import { Component, OnInit, ElementRef } from '@angular/core';
import { SlideInOutAnimation } from '../../../app.animations';

@Component({
  selector: 'app-risk-explore-services',
  templateUrl: './risk-explore-services.component.html',
  styleUrls: ['./risk-explore-services.component.scss'],
  animations: [ SlideInOutAnimation ]
})
export class RiskExploreServicesComponent implements OnInit {
  animationState = 'out';
  showDetails = false;
  toggleServices = false;
  services = [
    {
      slug: 'property',
      icon: {
        src: 'assets/img/icons/property.svg',
        title: 'Property',
        active: false
      },
      list: [
        'On-site risk evaluations with grading and benchmarking',
        'Infrared thermography',
        'Surveys and property conservation inspections',
        'Water damage mitigation',
        'Builders’ Risk inspections benchmarking',
        'Business income consultation',
        'Drones and rooftop evaluations including visual and thermal',
        'Human element training',
        'Sprinkler and fire protection plan reviews',
        'Business continuity and disaster recovery consultation',
        'Fire protection witnessing and testing'
      ]
    },
    {
      slug: 'general-liability',
      icon: {
        src: 'assets/img/icons/general-liability.svg',
        title: 'General Liability',
        active: false
      },
      list: [
        'Services designed to address premises liability exposures to minimize loss severity and frequency',
        'Site assessments with documentation of the inadequacies identified (e.g., unsafe walking surfaces, poor lighting)',
        'Slip, trip, and fall prevention',
        'Industrial hygiene testing',
        'Fire life safety assessments for high rise occupancies',
        'Special events consulting to identify potential hazards and prevent losses',
        'Risk consulting for facilities with public assembly exposures',
        'Sophisticated technology (e.g., digital cameras to record broken sidewalks, slip meters to measure slipperiness of surfaces)',
        'Products Liability (e.g., product profiles, large loss insights)',
        'Presentation of risk improvement plans for patron safety'
      ]
    },
    {
      slug: 'workers-comp',
      icon: {
        src: 'assets/img/icons/workers-comp.svg',
        title: 'Workers Comp',
        active: false
      },
      list: [
        'Customized risk management programs and services targeted at keeping the workplace safe',
        'Safety Program Infrastructure Development - safety committee development, accident investigation, and supervisor accountability',
        'Partnership with CLMI offers free and discounted safety training resources',
        'Comprehensive Safety Program Audits',
        'Slip Trip and Fall Risk Analysis and testing',
        'To The Point Safety Bulletins',
        'Risk Assessments'
      ]
    },
    {
      slug: 'cyber',
      icon: {
        src: 'assets/img/icons/cyber.svg',
        title: 'Cyber',
        active: false
      },
      list: [
        '“Password Defense,” a complimentary password manager application',
        'Premier Partner Network of pre-qualified service providers to offer solutions to help protect against key risks',
        'Signature assessments provided by a panel of Chubb pre-approved vendors at a pre-negotiated flat rate',
        'Online Cyber Security Education includes two complimentary classes: Security Awareness Basics & Security Awareness for Information Technology'
      ]
    },
    {
      slug: 'environmental',
      icon: {
        src: 'assets/img/icons/environmental.svg',
        title: 'Environmental',
        active: false
      },
      list: [
        'Extensive Experience in Environmental Consulting and Environmental Engineering.',
        'Diverse knowledge and expertise in a wide range of industries and services',
        'Certifications include: Certified Hazardous Materials Manager, Environmental Risk Managers, and Certified Safety Professionals',
        'Extensive Experience in Environmental Liability Issues and Emerging Environmental Hazards',
        'Environmental Stewardship and Environmental Management',
        'Environmental Compliance Assessments',
        'Indoor Air and Mold Management Assistance',
        'Due Diligence Planning',
        'Hazardous Waste Management',
        'Training'
      ]
    },
    {
      slug: 'equipment-breakdown',
      icon: {
        src: 'assets/img/icons/equipment-breakdown.svg',
        title: 'Equipment Breakdown',
        active: false
      },
      list: [
        'Ammonia, Electrical, Turbine and other Maintenance Risk Assessments and Tools (RMATS)',
        'Boiler and pressure vessel inspections conducted by engineers licensed by the National Board of Boiler and Pressure Vessel Inspectors',
        'Infra-red thermography to identify potential hot spots without interrupting business operations',
        'Non-destructive examination testing',
        'On-site risk evaluations',
        'Ultrasonic testing',
        'Specialized vendor services (e.g., transformer oil/gas analysis, lubricating oil analysis,  vibration analysis)'
      ]
    },
    {
      slug: 'auto',
      icon: {
        src: 'assets/img/icons/auto.svg',
        title: 'Auto',
        active: false
      },
      list: [
        'Fleet Safety Program Development, Review, and Audits',
        'Assistance with DOT Regulations and File Review',
        'Vehicle Preventative Maintenance Programs Review',
        'Accident Trend Analysis',
        'Driver Safety Awareness Program',
        'Driver Selection & Management Policies',
        'Follow/Ride-Along Observation Reports',
        'Accident Kits',
        'Fleet Safety Training',
        'CLMI Safety Training Resources',
        'To The Point Fleet Safety Bulletins',
        'Driver Recognition Safety Award Program',
        'Motor Vehicle Resource Tool Kit',
        'Risk Assessments on Regulated & Non-Regulated Vehicles'
      ]
    },
    {
      slug: 'product-liability',
      icon: {
        src: 'assets/img/icons/product-liability.svg',
        title: 'Product Liability',
        active: false
      },
      list: [
        'Services to address exposures resulting from:',
        'Commercial product defects',
        'Industrial product defects',
        'Consumer products',
        'Component parts',
        'Raw materials',
        'Manufacturers',
        'Distributors',
        'Retailers',
        'On-Site and Virtual Training Programs',
        'Key Concepts in Products Liability Risk Management',
        'Focused programs on Warning Labels, Instruction Manuals, etc.',
        'PinPoint Risk Assessment to quantifying risks and assess risk management opportunities',
        'Collateral Material Reviews including warning labels, instruction manuals, and marketing materials',
        'Site surveys to identify products liability risk management opportunities for improvements'
      ]
    }
  ];

  constructor(public el: ElementRef) { }

  ngOnInit() {
  }

  iconClick(service, target, event) {
    const details = document.querySelector('.explore--details'),
      parentRow = document.querySelector(target);
    let iconWidth = 228; // large size

    document.querySelectorAll('.explore--icon').forEach(i => {
      i.classList.remove('active');
      iconWidth = i.clientWidth;
    });

    if (event.currentTarget.classList.contains('icon-open')) { // closed, open it

      this.el.nativeElement.querySelectorAll('.icon-close').forEach(
        a => {
          a.classList.remove('icon-close'),
          a.classList.add('icon-open');
        });

      event.currentTarget.classList.add('active');
      event.currentTarget.classList.remove('icon-open');
      event.currentTarget.classList.add('icon-close');

      let l = '';
      if (service.list.length) {
        service.list.forEach((s, i) => {
          l += '<li class="f-col">' + s + '</li>';
        });
        details.innerHTML = '<ul class="f-row">' + l + '</ul>';
        details.classList.remove('is-disabled');
        if (iconWidth === 228) {// large size, add it after the row
          parentRow.after(details);
          parentRow.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        } else {
          event.currentTarget.after(details);
          details.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
          // window.scroll({ top: details.offsetTop - 70, behavior: 'smooth' });
        }
        window.dataLayer.push({
          'event': 'exploreServicesEvent',
          'eventCategory': 'Explore Services Icon',
          'eventAction': 'Clicked on ' + service.icon.title,
          'eventLabel': 'Open ' + service.icon.title
        });
      }
    } else {
      if (details.clientHeight > 0) {// details are showing
          this.el.nativeElement.querySelectorAll('.active').forEach(a => a.classList.remove('active'));
          this.el.nativeElement.querySelectorAll('.icon-close').forEach(
            a => {
              a.classList.remove('icon-close'),
              a.classList.add('icon-open');
            });
          details.classList.add('is-disabled');
          details.innerHTML = '';
          // this.showDetails = false;
        }
        window.dataLayer.push({
          'event': 'exploreServicesEvent',
          'eventCategory': 'Explore Services Icon',
          'eventAction': 'Clicked on ' + service.icon.title,
          'eventLabel': 'Close ' + service.icon.title
        });
    }
  }

  toggleDiv(divName: string) {
    this.toggleServices = !this.toggleServices;
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
    document.querySelector('#explore').scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    const toggle = (this.toggleServices) ? 'Open' : 'Close';
    window.dataLayer.push({
      'event': 'exploreServicesEvent',
      'eventCategory': 'Explore Services Toggle',
      'eventAction': 'Clicked on Explore Services Toggle',
      'eventLabel': toggle + ' Explore Services'
    });
  }

}
