import { Component, OnInit, ViewEncapsulation, HostListener, Host } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from 'src/app/app.component';
import { FlipInOutAnimation } from 'src/app/app.animations';

@Component({
  selector: 'app-risk',
  templateUrl: './risk.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./risk.component.scss'],
  animations: [ FlipInOutAnimation ]
})
export class RiskComponent implements OnInit {

  links = [
    {
      title: 'Our experts',
      mobTitle: 'Experts',
      href: 'risk-engineering',
      fragment: 'experts',
    },
    {
      title: 'Our clients',
      mobTitle: 'Clients',
      href: 'risk-engineering',
      fragment: 'clients',
    },
    {
      title: 'Resource center',
      mobTitle: 'Resources',
      href: 'risk-engineering',
      fragment: 'resources',
    }
  ];

  section1 = {
    title: 'experts',
    header: 'Our experts',
    description: 'Meet a few of our specialists dedicated to protecting your business.',
    color: 'blue',
    tiles: [
      {
        x: 'left',
        y: 'bottom',
        img: {
          src: 'assets/img/risk-section1-tile1@2x.jpg',
          x: 'right',
          y: 'top',
        },
        name: 'Tina M.',
        region: 'Midwest Region',
        jobtitle: 'Senior Workers Comp Risk Specialist',
        description: 'Four certifications and licenses and diverse commercial experience in risk engineering'
      },
      {
        x: 'right',
        y: 'top',
        img: {
          src: 'assets/img/risk-section1-tile2@2x.jpg',
          x: 'left',
          y: 'bottom',
        },
        name: 'Charles B.',
        region: 'New Jersey Region',
        jobtitle: 'Equipment Breakdown Specialist',
        description: 'Licensed by the National Board of Boiler and Pressure Vessel Inspectors'
      },
      {
        x: 'left',
        y: 'bottom',
        img: {
          src: 'assets/img/risk-section1-tile3@2x.jpg',
          x: 'right',
          y: 'top',
        },
        name: 'Chris D.',
        region: 'Pacific Region',
        jobtitle: 'Senior Property Risk Specialist',
        description: 'Certified Fire Protection Specialist; Board Certified Safety Professional, Engineer-in-Training'
      }
    ]
  };

  tiles = [
    {
      headline: ''
    }
  ];

  carouselStats = [
    {
      id: 'risk-stat1',
      img: 'assets/img/risk-stat-1@2x.png',
      svg: this.sanitizer.bypassSecurityTrustHtml('<svg xmlns="http://www.w3.org/2000/svg" width="328" height="224" viewBox="0 0 328 224"><g id="_280professionals" data-name="280professionals" transform="translate(-72 -1484)"><g id="Rectangle_3560" data-name="Rectangle 3560" transform="translate(72 1484)" fill="#ffffff" stroke="#6dbb20" stroke-width="4" opacity="0.75"><rect width="328" height="224" stroke="none"/><rect x="2" y="2" width="324" height="220" fill="none"/></g><text id="risk-stat1" data-name="280" transform="translate(102 1581)" fill="#6dbb20" font-size="86" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="0">280</tspan></text><text id="expert_risk_engineering_professionals_across_North_America" data-name="expert risk engineering professionals across North America" transform="translate(103 1605)" fill="#333" font-size="20" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="16">expert risk engineering </tspan><tspan x="0" y="44">professionals across</tspan><tspan x="0" y="72">North America</tspan></text></g></svg>'),
      alt: '280 expert risk engineering professionals across North America',
      animation: { c: 1, end: 280 }
    },
    {
      id: 'risk-stat2',
      img: 'assets/img/risk-stat-2@2x.png',
      svg: this.sanitizer.bypassSecurityTrustHtml('<svg xmlns="http://www.w3.org/2000/svg" width="328" height="224" viewBox="0 0 328 224"><g id="_15years" data-name="15years" transform="translate(-424 -1484)"><g id="Rectangle_3559" data-name="Rectangle 3559" transform="translate(424 1484)" fill="#ffffff" stroke="#6dbb20" stroke-width="4" opacity="0.75"><rect width="328" height="224" stroke="none"/><rect x="2" y="2" width="324" height="220" fill="none"/></g><text id="risk-stat2" data-name="15 years" transform="translate(454.5 1605)" fill="#6dbb20" font-size="79" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="0">15</tspan><tspan x="80px" y="0">years</tspan></text><text id="Nearly" transform="translate(455 1512)" fill="#333" font-size="20" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="16">Nearly</tspan></text><text id="average_tenure" data-name="average tenure" transform="translate(455 1635)" fill="#333" font-size="20" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="16">average tenure</tspan></text></g></svg>'),
      alt: 'Nearly 15 years average tenure',
      animation: { c: 2, end: 15 }
    },
    {
      id: 'risk-stat3',
      img: 'assets/img/risk-stat-3@2x.png',
      svg: this.sanitizer.bypassSecurityTrustHtml('<svg xmlns="http://www.w3.org/2000/svg" width="328" height="224" viewBox="0 0 328 224"><g id="_70percent" data-name="70percent" transform="translate(-776 -1484)"><g id="Rectangle_3558" data-name="Rectangle 3558" transform="translate(776 1484)" fill="#ffffff" stroke="#6dbb20" stroke-width="4" opacity="0.75"><rect width="328" height="224" stroke="none"/><rect x="2" y="2" width="324" height="220" fill="none"/></g><text id="risk-stat3" data-name="70%" transform="translate(806 1611)" fill="#6dbb20" font-size="86" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="0">70</tspan ><tspan x="90px" y="0">%</tspan></text><text id="hold_industry-specific_designations" data-name="hold industry-specific designations" transform="translate(807 1635)" fill="#333" font-size="20" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="16">hold industry-specific </tspan><tspan x="0" y="44">designations</tspan></text><text id="More_than" data-name="More than" transform="translate(807 1512)" fill="#333" font-size="20" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="16">More than</tspan></text></g></svg>'),
      alt: 'More than 70% hold industry-specific designations',
      animation: { c: 1, end: 70 }
    }
  ];

  heroToggle = true;

  @HostListener('window:resize', ['$event']) checkResize() {
    this.slideChangeToggle();
  }

  @HostListener('window:scroll', ['$event']) checkScroll() {
    this.slideChangeToggle();
  }

  constructor(@Host() public app: AppComponent, protected sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.slideChangeToggle();
  }

  ngAfterViewInit(): void {
    this.slideChangeToggle();
  }

  slideChangeToggle() {
    if (window.pageYOffset > window.innerHeight / 2 - 150) {
      this.heroToggle = false;
    } else {
      this.heroToggle = true;
    }
  }


}
