import { Component, OnInit } from '@angular/core';
import { SlideInOutAnimation } from '../../../app.animations';

@Component({
  selector: 'app-claims-explore-services',
  templateUrl: './claims-explore-services.component.html',
  styleUrls: ['./claims-explore-services.component.scss'],
  animations: [ SlideInOutAnimation ]
})

export class ClaimsExploreServicesComponent implements OnInit {
  animationState = 'out';

  toggleServices = false;

  services = [
    {
      header: 'Chubb Trusted Service Network',
      description: 'Over 3,000 vendors vetted by us (e.g., art restoration, fire prevention, contractors, auto repair); option to use your own preferred mitigation and restoration vendor.'
    },
    {
      header: 'Chubb House Counsel',
      description: 'Nearly 100 specialized attorneys staffed in law offices throughout the U.S. provide world-class legal representation while costing 40% lower than the average outside defense counsel.'
    },
    {
      header: 'RMIS Dimensions',
      description: 'Web-based, real-time risk management tool for loss data analysis; location code capability, scheduled reports via email.'
    },
    {
      header: 'Litigation Cost Management',
      description: 'Manages comprehensive legal bill audit process with no additional Loss Adjustment Expenses for you.'
    },
    {
      header: 'Special Investigations Unit',
      description: 'Discipline-specific specialists engaged to investigate potentially fraudulent claims, at no extra cost to you, that lead to premium savings for our clients (over 6,000 potentially fradulent claims reviewed annually).'
    },
    {
      header: '4D Predictive Modeling',
      description: 'Advanced predictive analytics technology and resources to turn disparate claim data into actionable information leading to improved risk engineering services, faster claims resolutions, decreased claim costs, and fewer cases that require laborious litigation.'
    },
    {
      header: 'Aerial Imagery',
      description: 'Satellite imagery, drone technology, roof inspections, flood damage inspections, all conducted to assess and expedite claim processing.'
    },
    {
      header: 'Litigation Management Unit',
      description: 'Highest quality legal services providing resolution methods, such as arbitration and mediation, to achieve a desired outcome.'
    },
    {
      header: 'Recovery and Subrogation Unit',
      description: 'Identify and pursue recovery dollars from third parties responsible for damages; leads to reimbursement for deductible and repair of loss history, which result in lower premiums for you.'
    },
    {
      header: 'Claimview',
      description: 'Real-time access to claim notes for select lines of business to keep you updated on status as we move through the claims process.'
    },
    {
      header: 'Dedicated CAT Team',
      description: 'We prepare for catastrophic events year round to help ensure no matter the size or scope of the event, we have Chubb claims handlers ready to respond.'
    },
    {
      header: 'National Claims Centers',
      description: 'Three service centers that serve the entire country from taking first notice of loss to adjusting claims across all product lines.'
    }
  ];
  
  constructor() { }

  ngOnInit() {
  }

  toggleDiv(divName: string) {
    this.toggleServices = !this.toggleServices;
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
    document.querySelector('#explore').scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    const toggle = (this.toggleServices) ? 'Open' : 'Close';
    window.dataLayer.push({
      'event': 'exploreServicesEvent',
      'eventCategory': 'Explore Services Toggle',
      'eventAction': 'Clicked on Explore Services Toggle',
      'eventLabel': toggle + ' Explore Services'
    });
  }

}
