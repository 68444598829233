import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Linear, TimelineMax } from 'gsap';

declare const $: any;
@Component({
  selector: 'app-carousel-stats',
  templateUrl: './carousel-stats.component.html',
  styleUrls: ['./carousel-stats.component.scss']
})
export class CarouselStatsComponent implements OnInit {

  animationTriggered = false;
  rediscServStats1Timeline = new TimelineMax({repeat: 2, repeatDelay: 3});
  rediscServStats2Timeline = new TimelineMax({repeat: 2, repeatDelay: 3});
  rediscServStats3Timeline = new TimelineMax({repeat: 2, repeatDelay: 3});

  @Input() carouselData: any;
  @Input() color: any;

  @HostListener('document:scroll', ['$event']) checkScroll() {
    if (window.pageYOffset >= window.innerHeight && !this.animationTriggered) {
      this.initNumbers();
    }
  }

  constructor() { }

  ngOnInit() {
  }


  initNumbers() {
    const stats1Div = document.querySelector('#' + this.carouselData[0].id + ' tspan'),
      stats2Div = document.querySelector('#' + this.carouselData[1].id + ' tspan'),
      stats3Div = document.querySelector('#' + this.carouselData[2].id + ' tspan'),
      stats1 = this.carouselData[0].animation,
      stats2 = this.carouselData[1].animation,
      stats3 = this.carouselData[2].animation;

    if (stats1Div) {
      this.rediscServStats1Timeline.to(stats1, 1.5, { c: stats1.end, onUpdate: function () {
          stats1Div.textContent = Math.round(stats1.c).toString(); $('.stats').trigger('refresh.owl.carousel');
        }, ease: Linear.easeInOut}, 1);
      this.rediscServStats2Timeline.to(stats2, 1.5, { c: stats2.end, onUpdate: function () {
          stats2Div.textContent = Math.round(stats2.c).toString(); $('.stats').trigger('refresh.owl.carousel');
        }, ease: Linear.easeInOut}, 1);
      this.rediscServStats3Timeline.to(stats3, 1.5, { c: stats3.end, onUpdate: function () {
          stats3Div.textContent = Math.round(stats3.c).toString(); $('.stats').trigger('refresh.owl.carousel');
        }, ease: Linear.easeInOut}, 1);
      this.animationTriggered = true;
    }
  }

}
