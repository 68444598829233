import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent implements OnInit {

  links = [
    { link: '/intro', title: 'Why Chubb', titlecta: 'Explore more Why Chubb'},
    { link: '/risk-engineering', title: 'Risk Engineering', titlecta: 'Explore more Risk Engineering'},
    { link: '/claims', title: 'Claims', titlecta: 'Explore more Claims'},
  ];
  constructor() { }

  ngOnInit() {
  }

}
