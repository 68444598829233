import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-routerlink',
  templateUrl: './btn-routerlink.component.html',
  styleUrls: ['./btn-routerlink.component.scss']
})
export class BtnRouterlinkComponent implements OnInit {

  @Input() link: any;
  @Input() title: any;
  @Input() titlecta: any;
  @Input() color: any;
  @Input() class: any;

  constructor() { }

  ngOnInit() {
  }

}
