import { Component, OnInit, ViewChild, Input } from '@angular/core';
declare const $: any;
@Component({
  selector: 'app-risk-section2',
  templateUrl: './risk-section2.component.html',
  styleUrls: ['./risk-section2.component.scss']
})
export class RiskSection2Component implements OnInit {

  public carouselTileItems: Array<any> = [0, 1, 2, 3, 4, 5];
  public carouselTiles = [
    /*{
      title: 'Infrared technology prevents potentially explosive fire claim',
      image: 'assets/img/icons/flame.svg',
      alt: 'Flame icon',
      content: [
        'As part of the renewal process, Chubb’s risk engineering stewardship summary called for an annual fire pump test at a manufacturing facility. As a first-time Chubb renewing client, all previous fire pump tests had been conducted with traditional test equipment like tachometers and flow metering equipment. However, Chubb insureds benefit from the utilization of advanced technology in their testing to ensure the highest quality and most thorough assessments.',
        'During the Chubb inspection, while the panels were open during the pump test, a handheld infrared camera was used to capture images of the fire pumps’ internal electrical panel. The new infrared technology captured a critical indication with a high probability of failure of the circuit breaker within the control panel. Failure of this circuit breaker in the event of a fire could have resulted in the pump not functioning.',
        'The manufacturer replaced the circuit breaker based on this finding. This discovery potentially saved a significant fire loss and would have gone unnoticed without the infrared images obtained by Chubb.'
      ]
    },
    {
      title: 'A jurisdictional visit prevents a significant property loss',
      image: 'assets/img/icons/jurisdictional-visit.svg',
      alt: 'Jurisdiction icon',
      content: [
        'A hotel was in need of an inspection to certify that their equipment was in compliance with the National Board and the jurisdiction having authority codes.  Chubb visited the property and found a 4-inch section of the main water line supplying the building with potable water was severely corroded and showing signs of leakage.  The Chubb team approached the client and broker expressing concern over the corroded pipe and the loss potential as water damage is a leading source of claims activity within the industry. A failure of this pipe would have caused significant flooding/water damage of the first floor resulting in PD damages, EE exposure for emergency cleanup, and BI (100% for a couple of days and then partial BI until room repairs were complete). Because the team elevated the recommendation to client and broker, repairs were successfully completed in a short period of time.'
      ]
    },
    {
      title: 'Installing a sprinkler system may have saved property... and jobs',
      image: 'assets/img/icons/sprinkler.svg',
      alt: 'Sprinkler icon',
      content: [
        'During a property inspection of a prospective insured’s food manufacturing facility, it was discovered that the property was devoid of sprinklers and a fire detection system.  When Chubb explained the exposure to the contact and emphasized the importance of these risk mitigation tools, the contact advised the company was not interested in installing a sprinkler system due to cost and the fear of the sprinkler water damaging its food.  Thus, Chubb declined to insure this property.  Less than a year later, a 3-alarm fire at the facility quickly grew out of control and local fire fighters spent more than 12 hours fighting the blaze.  Damage to equipment and the building resulted in major financial loss. Consequently, the manufacturer was forced to lay off over 100 employees.'
      ]
    },
    {
      title: 'Prospect ignores Chubb’s risk mitigation recommendations',
      image: 'assets/img/icons/mitigationrecommendations.svg',
      alt: 'Risk Mitigation icon',
      content: [
        'During an environmental site survey at a prospective client’s plant, Chubb’s environmental risk engineer noted that portions of the site and its infrastructure were significantly aged and likely to become the source of a failure.  Chubb recommended installing proper and clearly marked labels on all chemical loading values and implementing behavior changes such as requiring that loading operations be supervised by a manager holding the master key for the product transfer valves.  When the business owner indicated they’d not be implementing these changes, Chubb declined to insure the business.  Three years later, inadvertent mixing of incompatible materials by third party delivery truck drivers created a large cloud of chlorine gas that impacted an entire community.  Numerous injuries and damages were reported.'
      ]
    },*/
    {
      title: 'Improving fleet safety',
      image: 'assets/img/icons/fleetsafety.svg',
      alt: 'Fleet Safety icon',
      content: [
        'My film production company’s primary focus is our esteemed awards show honoring the year’s best directors, actors, and movies. To make that show happen, our employees do a significant amount of driving in the greater Los Angeles area. We turned to Chubb’s fleet safety specialists to raise safe driving awareness by conducting defensive driving courses. Our employees left with improved knowledge and skills to safely maneuver through the heavy L.A. traffic, leading to improved driving and fewer collisions.'
      ]
    },
    {
      title: 'Ensuring industrial hygiene',
      image: 'assets/img/icons/industrialhygiene.svg',
      alt: 'Industrial Hygiene icon',
      content: [
        'Last year, hydrogen sulfide training was an important service deliverable for us and Chubb trained over 235 of our field technicians in 15 locations throughout the country. In addition, on a rush basis, Chubb conducted industrial hygiene sampling at a new compressor site due to our concerns about potential benzene exposure. Results indicated levels well below permissible limits, which allowed our operation to resume work and avoid disruption.'
      ]
    },
    {
      title: 'Avoiding property loss',
      image: 'assets/img/icons/propertyloss.svg',
      alt: 'Property Loss icon',
      content: [
        'Our hotel needed an inspection to certify that our equipment meets local compliance standards. During inspection, Chubb discovered a corroded water line which was showing signs of leakage. Left undetected, failure of this pipe may have caused significant flooding and water damage and resulted in property damages, extra expense for emergency cleanup, and lost business income while room repairs were completed. Chubb identified the issue and offered a recommendation to remediate, allowing us to quickly repair the damaged line.'
      ]
    },{
      title: 'Reducing likelihood of equipment failure',
      image: 'assets/img/icons/equipmentfailure.svg',
      alt: 'Equipment Failure icon',
      content: [
        'As part of our renewal, Chubb’s annual review called for a fire pump test. As a first-time renewing Chubb client, all previous fire pump tests were conducted with traditional test equipment like tachometers and flow metering equipment.  During Chubb’s inspection, an infrared camera captured images of the internal electrical panel, identifying a high probability of failure.  In the event of a fire, failure of a circuit breaker could lead to pump malfunction.  Chubb’s ability to highlight this issue ensured continuity of our fire suppression system.'
      ]
    },
    {
      title: 'Addressing ergonomic exposures',
      image: 'assets/img/icons/ergonomics.svg',
      alt: 'Ergonomics icon',
      content: [
        'My financial services firm was averaging over five ergonomic claims per year. Chubb helped us identify and remove office-based workstation exposures by implementing web-based training so employees could perform ergonomic exposure self-assessments. Chubb also helped our Office Ergonomics Evaluator get certified through a program offered by a well-known, local university. As a result, we’ve since reduced our average number of ergonomic claims by over 50%, and the average cost per claim was also cut in half while simultaneously increasing our employee count by 25%.'
      ]
    },
    {
      title: 'Mitigating cyber vulnerabilities',
      image: 'assets/img/icons/cybervulnarabilities.svg',
      alt: 'Cyber Vulnerabilities icon',
      content: [
        'Cyber security is top of mind for our clinical trials company because we work with a lot of confidential patient information. Recently, Chubb helped us improve our cyber security by facilitating a number of cyber assessments. The assessments included incident response planning, network vulnerability testing, and a phishing simulation. In addition, we collaborated with Chubb to offer online security education training for our employees.'
      ]
    }
  ];

  leftNavDisabled = false;
  rightNavDisabled = false;
  carouselColor: 'green';
  points = [];

  @Input() app: any;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $(document).ready(function(){
      const owl = $('.owl-stories').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText : ['<button class="leftRs"></button>',
                    '<button class="rightRs"></button>'],
        items: 1,
        dots: true,
        dotsContainer: ''
      });

      owl.on('changed.owl.carousel', function(event) {
        window.dataLayer.push({
          'event': 'carouselEvent',
          'eventCategory': 'Carousel Click',
          'eventAction': 'Clicked on carousel control',
          'eventLabel': 'Move to slide ' + (event.page.index + 1)
        });
      });
    });
  }

}
