/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carousel-stats.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./carousel-stats.component";
var styles_CarouselStatsComponent = [i0.styles];
var RenderType_CarouselStatsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarouselStatsComponent, data: {} });
export { RenderType_CarouselStatsComponent as RenderType_CarouselStatsComponent };
function View_CarouselStatsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "item"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.svg; _ck(_v, 0, 0, currVal_0); }); }
export function View_CarouselStatsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselStatsComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.carouselData; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "f-row stats owl-carousel owl-theme ", _co.color, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_CarouselStatsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel-stats", [], null, [["document", "scroll"]], function (_v, en, $event) { var ad = true; if (("document:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CarouselStatsComponent_0, RenderType_CarouselStatsComponent)), i1.ɵdid(1, 114688, null, 0, i3.CarouselStatsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarouselStatsComponentNgFactory = i1.ɵccf("app-carousel-stats", i3.CarouselStatsComponent, View_CarouselStatsComponent_Host_0, { carouselData: "carouselData", color: "color" }, {}, []);
export { CarouselStatsComponentNgFactory as CarouselStatsComponentNgFactory };
