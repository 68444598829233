import { Component, OnInit, Input, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-pagedown-bug',
  templateUrl: './pagedown-bug.component.html',
  styleUrls: ['./pagedown-bug.component.scss']
})
export class PagedownBugComponent implements OnInit {

  @Input() title: any;
  @Input() backgroundImage: any;
  @Input() target: any;

  @HostListener('window:resize', ['$event']) checkResize() {
    this.stickyBug();
  }

  @HostListener('window:scroll', ['$event']) checkScroll() {
    this.stickyBug();
  }

  constructor(public el: ElementRef) { }

  ngOnInit() {
  }

  // Sticky Bug
  stickyBug() {
    const bug = document.querySelector('.claims--bug'),
      endHeight = document.querySelector('#' + this.target);
    if (window.pageYOffset < window.innerHeight - 140) {
      bug.classList.add('sticky');
    } else if (window.innerWidth > 1392 && endHeight.getBoundingClientRect().top > 0) {
      bug.classList.add('sticky');
    } else {
      bug.classList.remove('sticky');
    }
  }

  scrollToElement($element): void {
    document.querySelector('#' + $element).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    window.dataLayer.push({
      'event': 'claimsBugEvent',
      'eventCategory': 'Claims Bug Click',
      'eventAction': 'Clicked on Claims Bug',
      'eventLabel': 'Claims Bug'
    });
  }

}
