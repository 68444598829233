<section id="{{ sectiondata.title }}" [attr.name]="sectiondata.title" class="page--background skinny f-row">
  <div class="f-col">
      <div id="marker1" class="marker hide-on-init" animateOnScroll animationName="animated zoomIn"></div>
      <aside>
          <h1>{{ sectiondata.header }}</h1>
      </aside>
      <aside>
        <h3>{{ sectiondata.description }}</h3>
      </aside>
  </div>

  <div class="f-col tiles">
    <div class="tiles__stagger hide-on-init" style="height: 345px;" *ngFor="let tile of sectiondata.tiles" animateOnScroll animationName="animated fadeInUp">
      <div class="tiles--info abs {{ tile.x }} {{ tile.y }} front {{ sectiondata.color }}">
        <h2>{{ tile.name }}</h2>
        <h3><i>{{ tile.region }}</i></h3>
        <h4>{{ tile.jobtitle }}</h4>
        <p>{{ tile.description }}</p>
      </div>
      <div class="tiles--image abs {{ tile.img.y }} {{ tile.img.x }}" [ngStyle]="{'background-image': 'url(' + tile.img.src + ')'}"></div>
    </div>
  </div>
</section>