/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagedown-bug.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./pagedown-bug.component";
var styles_PagedownBugComponent = [i0.styles];
var RenderType_PagedownBugComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PagedownBugComponent, data: {} });
export { RenderType_PagedownBugComponent as RenderType_PagedownBugComponent };
export function View_PagedownBugComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "claims--bug sticky"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToElement(_co.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-image": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, (("url(" + _co.backgroundImage) + ")")); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
export function View_PagedownBugComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pagedown-bug", [], null, [["window", "resize"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).checkResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:scroll" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).checkScroll($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_PagedownBugComponent_0, RenderType_PagedownBugComponent)), i1.ɵdid(1, 114688, null, 0, i3.PagedownBugComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PagedownBugComponentNgFactory = i1.ɵccf("app-pagedown-bug", i3.PagedownBugComponent, View_PagedownBugComponent_Host_0, { title: "title", backgroundImage: "backgroundImage", target: "target" }, {}, []);
export { PagedownBugComponentNgFactory as PagedownBugComponentNgFactory };
