<!-- app-nav-chubb></app-nav-chubb -->

<section class="main" role="main" aria-live="polite">
  <router-outlet></router-outlet>
</section>

<div id="modal" class="modal">
  <div class="modal-content">
      <span class="close" (click)="modalClose();">&times;</span>
      <video #vid id="my-video" class="video-js" [poster]="poster" style="margin: auto; width: 600px; height: 400px;" >
          <source src="" type="" />
      </video>
  </div>
</div>