/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./btn-watch.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./btn-watch.component";
var styles_BtnWatchComponent = [i0.styles];
var RenderType_BtnWatchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BtnWatchComponent, data: {} });
export { RenderType_BtnWatchComponent as RenderType_BtnWatchComponent };
export function View_BtnWatchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "btn"]], [[1, "data-video-title", 0], [1, "href", 4], [8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(2, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Watch "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-play"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_5 = "btn"; var currVal_6 = _co.color; _ck(_v, 1, 0, currVal_5, currVal_6); var currVal_7 = _co.link; _ck(_v, 2, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.href; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.title, ""); var currVal_3 = i1.ɵnov(_v, 2).target; var currVal_4 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_BtnWatchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-btn-watch", [], null, null, null, View_BtnWatchComponent_0, RenderType_BtnWatchComponent)), i1.ɵdid(1, 114688, null, 0, i4.BtnWatchComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BtnWatchComponentNgFactory = i1.ɵccf("app-btn-watch", i4.BtnWatchComponent, View_BtnWatchComponent_Host_0, { href: "href", link: "link", title: "title", color: "color" }, {}, []);
export { BtnWatchComponentNgFactory as BtnWatchComponentNgFactory };
