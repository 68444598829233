import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-download-sidebyside',
  templateUrl: './download-sidebyside.component.html',
  styleUrls: ['./download-sidebyside.component.scss']
})
export class DownloadSidebysideComponent implements OnInit {

  @Input() data: any;
  webRoot = window.location.href.replace(window.location.hash, ``).replace(`index.aspx`, ``);

  constructor() { }

  ngOnInit() {
  }

}
