import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-risk-section3',
  templateUrl: './risk-section3.component.html',
  styleUrls: ['./risk-section3.component.scss']
})
export class RiskSection3Component implements OnInit {

  resources = [
    {
      img_src: 'assets/img/risk-resources-1@2x.png',
      title: 'Self-Service Resources',
      titleatt: 'Explore Risk Engineering Resource Center (opens in new window, requires login)',
      titlecta: 'Explore Risk Engineering Resource Center',
      description: 'On-demand library of information from some of the best minds in the risk management business.',
      checklist: [
        'Technical information',
        'Best practices',
        'Checklists',
        'Videos',
        'Training materials'
      ],
      // watch: 'https://chubb3.box.com/s/eu7p22e36pni9hkbjahvd8644g3drpub',
      watch: 'assets/video/RiskEngineeringResourceCenter_v4_June_26_2019.m4v',
      explore: `https://lcrc.chubb.com/index`
    },
    {
      img_src: 'assets/img/risk-resources-2@2x.png',
      title: 'Client Exchange for Select Accounts',
      titleatt: 'Explore Risk Engineering Client Exchange (opens in new window, requires login)',
      titlecta: 'Explore Risk Engineering Client Exchange',
      description: 'Qualified businesses are considered on-service and may receive additional specialized service from our specialists.',
      checklist: [
        'Custom service plans',
        'One-on-one access to experts',
        'Cutting-edge risk mitigation services',
        'Critical recommendations'
      ],
      // watch: 'https://chubb3.box.com/s/zwxw4ufn3ph4eegr0fg74ltpg6r4hp86',
      watch: 'assets/video/RiskEngineeringClientExchange_v4_June_29_2019.m4v',
      explore: `https://lcrc.chubb.com/index`
    }
  ];

  checklist = {
    title: "Risk management checklist",
    description: "Check out our checklist to start the process of evaluating your risk management program.",
    image: "assets/img/risk-checklist-photo@2x.jpg",
    download_href: "assets/pdf/Chubb_RESChecklist.pdf",
    titleatt: "Risk management checklist (opens in new window)",
    download_title: "Risk management checklist",
    email_id:"42_1",
    url: "assets/pdf/Chubb_RESChecklist.pdf",
    social_title: "Chubb’s Risk Management Checklist",
    social_body: "Assess your risk preparedness and reduce the likelihood of a business disruption with Chubb’s risk management checklist.",
    email_subject: "Chubb’s Risk Management Checklist",
    email_body: "Assess your risk preparedness and reduce the likelihood of a business disruption with Chubb’s risk management checklist.",
    color: "flagship--green"
  };

  @Input() app: any;

  constructor() { }

  ngOnInit() {
  }

}
