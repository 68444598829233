import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';

import { AppComponent } from './app.component';
import { StaggerTilesComponent } from './shared/stagger-tiles/stagger-tiles.component';
import { FooterComponent } from './shared/footer/footer.component';
import { BtnShareComponent } from './shared/btn-share/btn-share.component';
import { BtnMoreComponent } from './shared/btn-more/btn-more.component';
import { BtnDownloadComponent } from './shared/btn-download/btn-download.component';
import { IntroComponent } from './pages/intro/intro.component';
import { RiskComponent } from './pages/risk/risk.component';
import { ClaimsComponent } from './pages/claims/claims.component';
import { RiskSection2Component } from './pages/includes/risk-section2/risk-section2.component';
import { RiskSection3Component } from './pages/includes/risk-section3/risk-section3.component';
import { RiskSection4Component } from './pages/includes/risk-section4/risk-section4.component';
import { RiskSection4BkpComponent } from './pages/includes/risk-section4-bkp/risk-section4.component';
import { RiskExploreServicesComponent } from './pages/includes/risk-explore-services/risk-explore-services.component';
import { BtnWatchComponent } from './shared/btn-watch/btn-watch.component';
import { ClaimsExploreServicesComponent } from './pages/includes/claims-explore-services/claims-explore-services.component';
import { CarouselStatsComponent } from './shared/carousel-stats/carousel-stats.component';
import { FooterNavComponent } from './shared/footer-nav/footer-nav.component';
import { BtnRouterlinkComponent } from './shared/btn-routerlink/btn-routerlink.component';
import { NavArrowBounceComponent } from './shared/nav-arrow-bounce/nav-arrow-bounce.component';
import { NavChubbComponent } from './shared/nav-chubb/nav-chubb.component';
import { NavAnchorDownComponent } from './shared/nav-anchor-down/nav-anchor-down.component';
import { PagedownBugComponent } from './pages/includes/pagedown-bug/pagedown-bug.component';
import { DownloadSidebysideComponent } from './shared/download-sidebyside/download-sidebyside.component';
import { ClaimsTestimonialsComponent } from './pages/includes/claims-testimonials/claims-testimonials.component';
import { NotFoundComponent } from './not-found/not-found.component';

declare global {
  interface Window { dataLayer: any; }
}

window.dataLayer = window.dataLayer || {};
@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    RiskComponent,
    ClaimsComponent,
    FooterComponent,
    BtnShareComponent,
    BtnMoreComponent,
    BtnRouterlinkComponent,
    StaggerTilesComponent,
    BtnDownloadComponent,
    RiskSection2Component,
    RiskSection3Component,
    RiskSection4Component,
    RiskSection4BkpComponent,
    RiskExploreServicesComponent,
    BtnWatchComponent,
    ClaimsExploreServicesComponent,
    CarouselStatsComponent,
    FooterNavComponent,
    NavArrowBounceComponent,
    NavChubbComponent,
    NavAnchorDownComponent,
    PagedownBugComponent,
    DownloadSidebysideComponent,
    ClaimsTestimonialsComponent,
    NotFoundComponent,
    // VideoPlayerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AnimateOnScrollModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
