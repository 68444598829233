<section id="explore" name="explore" class="page--background explore">
  <div class="explore--nav">
    <img src="assets/img/explore-headlights@2x.jpg" alt="Photo of car headlights" width="200" height="215" class="header-explore" />
    <div class="f-col explore--header">
        <h2>Explore services</h2>
        <p>We offer a variety of specialized, cutting-edge risk mitigation services to address the needs of your operation. Explore these services now.</p>
        <img src="assets/img/icons/explore-open@2x.png" width="431" height="55" (click)="toggleDiv();" *ngIf="!toggleServices" class="explore--open" alt="Open Explore services" title="Open Explore services" />
        <img src="assets/img/icons/explore-close@2x.png" width="431" height="55" (click)="toggleDiv();" *ngIf="toggleServices" class="explore--close" alt="Close Explore services" title="Close Explore services" />
    </div>
    <img src="assets/img/explore-forest@2x.jpg" alt="Photo of car on forest road" width="200" height="215" class="header-explore" />
  </div>

  <div class="explore--results animated fadeIn" *ngIf="toggleServices">
    
    <div class="f-row explore--icons" id="row1">
      <div *ngFor="let service of services; let i = index;">
        <div class="explore--icon icon-open" *ngIf="i < 4" (click)="this.iconClick(service, '#row1', $event)">
          <img src="{{ service.icon.src }}" alt="{{ service.icon.title }} icon" width="100" height="100" />
          <span class="explore--icon--title">{{ service.icon.title }}</span>
          <div class="pointer"></div>
        </div>
      </div>
    </div>

    <div class="f-row explore--icons"  id="row2">
      <div *ngFor="let service of services; let i = index;">
        <div class="explore--icon icon-open" *ngIf="i >= 4" (click)="this.iconClick(service, '#row2', $event)">
          <img src="{{ service.icon.src }}" alt="{{ service.icon.title }} icon" width="100" height="100" />
          <span class="explore--icon--title">{{ service.icon.title }}</span>
          <div class="pointer"></div>
        </div>
      </div>
    </div>
  
    <div class="explore--details f-row is-disabled"></div>
  </div>

</section>