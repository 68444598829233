import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NavArrowBounceComponent } from './shared/nav-arrow-bounce/nav-arrow-bounce.component';
import { HttpParams } from '@angular/common/http';

declare let videojs: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'rediscover-services';
  vidObj: any;
  poster = '';
  // video = '';
  navArrow = NavArrowBounceComponent.prototype;

  fragment: string;
  utmSource: string;
  utmCampaign: string;
  utmMedium: string;
  utmContent: string;
  utmTerm: string;
  dclid: string;
  gclid: string;

  @ViewChild('vid') vid: ElementRef;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const modal = document.getElementById('modal');
    if (event.target === modal) {
      modal.style.display = 'none';
      this.vidObj.pause();
    }
  }

  @HostListener('body:mousewheel', ['$event'])
  scrollIEFix(event) {

    if (navigator.userAgent.match(/Trident\/7\./)) {// if IE
        // remove default behavior
        event.preventDefault();

        // scroll without smoothing
        const wheelDelta = this.getWheelDelta(event);
        const currentScrollPosition = window.pageYOffset;
        window.scrollTo(0, currentScrollPosition - wheelDelta);
    }
  }

  constructor(public router: Router, public sanitizer: DomSanitizer, private route: ActivatedRoute) {}

  ngOnInit() {
    const source = this.getParamValueQueryString('utm_source');
    this.utmSource = this.cleanParams(source);
    const campaign = this.getParamValueQueryString('utm_campaign');
    this.utmCampaign = this.cleanParams(campaign);
    const medium = this.getParamValueQueryString('utm_medium');
    this.utmMedium = this.cleanParams(medium);
    const content = this.getParamValueQueryString('utm_content');
    this.utmContent = this.cleanParams(content);
    const term = this.getParamValueQueryString('utm_term');
    this.utmTerm = this.cleanParams(term);
    const dclid = this.getParamValueQueryString('dclid');
    this.dclid = this.cleanParams(dclid);
    const gclid = this.getParamValueQueryString('gclid');
    this.gclid = this.cleanParams(gclid);
    const hasUtm = source || campaign || medium || term || content || dclid || gclid;

    hasUtm ? window.dataLayer.push({
      event: 'utmLoad',
      utmSource: this.utmSource,
      utmCampaign: this.utmCampaign,
      utmMedium: this.utmMedium,
      utmContent: this.utmContent,
      utmTerm: this.utmTerm,
      dclid: this.dclid,
      gclid: this.gclid
    }) : '';

    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  ngAfterViewInit(): void {
    if (this.route.fragment) {
      setTimeout( () => {
        if (document.querySelector('#' + this.fragment)) {
          document.querySelector('#' + this.fragment).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        }
      }, 800);
    }
  }

  cleanParams( paramName: string ) {
    return paramName && paramName.includes('#') ? paramName.split('#')[0] : paramName;
  }

  getParamValueQueryString( paramName: string ) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  getWheelDelta(event) {
    return (
      event.wheelDelta ||
      -event.detail ||
      event.originalEvent.wheelDelta ||
      -event.originalEvent.detail ||
      -(event.originalEvent.deltaY * 25) ||
      null
    );
  }

  playVideo(url, type, poster) {
    if (window.innerWidth < 768) {
      document.location.href = url;
    } else {
      const options = {
        controls: true,
        autoplay: false,
        preload: 'auto' ,
        techOrder: ['youtube', 'html5'],
        sources: [{ 'type': type, 'src': url }],
        poster: poster
      };

      if (this.vidObj) {
        this.vidObj.poster(poster);
        this.vidObj.src({type: type, src: url});
      } else {
        this.vidObj  =  new videojs(this.vid.nativeElement, options, function onPlayerReady() {
          // videojs.log('Your player is ready!', this);
        });
      }
      document.getElementById('modal').style.display = 'block';
      this.vidObj.play();
    }
  }

  modalClose() {
    document.getElementById('modal').style.display = 'none';
    this.vidObj.pause();
  }
}
