import { Component, OnInit, HostListener, ElementRef, ViewChild, Host } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SlideInOutAnimation } from 'src/app/app.animations';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  animations: [SlideInOutAnimation]
})
export class IntroComponent implements OnInit {

  heroToggle = true;

  constructor(public el: ElementRef, protected sanitizer: DomSanitizer, @Host() public app: AppComponent) { }

  ngOnInit() {
    this.slideChangeToggle();
  }

  ngAfterViewInit(): void {
    this.slideChangeToggle();
  }

  @HostListener('window:resize', ['$event']) checkResize() {
    this.slideChangeToggle();
  }

  @HostListener('window:scroll', ['$event']) checkScroll() {
    this.slideChangeToggle();
  }

  slideChangeToggle() {
    if (window.pageYOffset > window.innerHeight - 150) {
      this.heroToggle = false;
    } else {
      this.heroToggle = true;
    }
  }
}
