/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./app.component";
import * as i4 from "@angular/platform-browser";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { vid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "section", [["aria-live", "polite"], ["class", "main"], ["role", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "modal"], ["id", "modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["vid", 1]], null, 1, "video", [["class", "video-js"], ["id", "my-video"], ["style", "margin: auto; width: 600px; height: 400px;"]], [[8, "poster", 4]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "source", [["src", ""], ["type", ""]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.poster; _ck(_v, 8, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["document", "click"], ["body", "mousewheel"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).clickout($event) !== false);
        ad = (pd_0 && ad);
    } if (("body:mousewheel" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).scrollIEFix($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i3.AppComponent, [i2.Router, i4.DomSanitizer, i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i3.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
