/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-anchor-down.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./nav-anchor-down.component";
var styles_NavAnchorDownComponent = [i0.styles];
var RenderType_NavAnchorDownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavAnchorDownComponent, data: {} });
export { RenderType_NavAnchorDownComponent as RenderType_NavAnchorDownComponent };
function View_NavAnchorDownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [], [[8, "className", 0], [1, "data-cta", 0], [8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToElement(_v.context.$implicit.fragment, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [["class", "tab-nav__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/img/downarrow.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "tab-nav__title--mob"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "tab-nav__link ", _v.context.$implicit.href, "-link scroll"); var currVal_1 = _v.context.$implicit.title; var currVal_2 = i1.ɵinlineInterpolate(1, "nav", _v.context.$implicit.fragment, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_3); var currVal_4 = _v.context.$implicit.mobTitle; _ck(_v, 5, 0, currVal_4); }); }
export function View_NavAnchorDownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["class", "tab-nav"], ["id", "anchorNav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "clearfix content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavAnchorDownComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.links; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_NavAnchorDownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav-anchor-down", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_NavAnchorDownComponent_0, RenderType_NavAnchorDownComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavAnchorDownComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavAnchorDownComponentNgFactory = i1.ɵccf("app-nav-anchor-down", i3.NavAnchorDownComponent, View_NavAnchorDownComponent_Host_0, { links: "links" }, {}, []);
export { NavAnchorDownComponentNgFactory as NavAnchorDownComponentNgFactory };
