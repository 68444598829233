<section class="page--background skinny f-row risk-checklist" id="risk-checklist">
    
    <div class="f-col" id="thought-leadership">
        <div id="marker4" class="marker hide-on-init" animateOnScroll animationName="animated zoomIn"></div>
        <aside>
            <h1>Thought leadership</h1>
        </aside>
        <aside>
          <h3>We are continually working with clients, partnering with industry experts, and mining our own claims data to stay in front of the latest loss trends. This insight helps us evolve our products and services to ensure we are best serving our customers.</h3>
        </aside>
    </div>

    <div class="f-col tiles testimonials--col66">
        <div class="risk-testimonials tiles--padtop">
            <div class="tiles__stagger">
              <div class="tiles--info abs right bottom front blue">
                <img src="assets/img/icons/new@2x.png" width="48" height="24" alt="NEW icon" class="icon-new" />
                <h2>Risk management checklist</h2>
                <p>Check out our checklist to start the process of evaluating your risk management program.</p>
                <app-btn-download 
                    href="assets/pdf/Chubb_RESChecklist.pdf"
                    title="Risk management checklist"
                    titleatt="Risk management checklist (opens in new window)"
                    download_title="Risk management checklist"
                    color="btn--white"></app-btn-download>
                <app-btn-share 
                    email_id="97_1"
                    url="assets/pdf/Chubb_RESChecklist.pdf"
                    web_root="{{ webRoot }}"
                    social_title="Chubb’s Risk Management Checklist"
                    social_body="Assess your risk preparedness and reduce the likelihood of a business disruption with Chubb’s risk management checklist."
                    email_subject="Chubb’s Risk Management Checklist"
                    email_body="Assess your risk preparedness and reduce the likelihood of a business disruption with Chubb’s risk management checklist."
                    color="btn--white"></app-btn-share>
              </div>
              <div class="tiles--image abs left top" [ngStyle]="{'background-image': 'url(assets/img/risk-leadership-checklist@2x.jpg)'}"></div>
            </div>
        </div>
    </div>
</section>

<div class="relative">
  <a id="tl-{{ insight.slug }}" *ngFor="let insight of insights;" class="offset"></a>
</div>
<section class="page--background no-image white leadership-tabs">
  <div class="f-row">
    <div class="leadership-tab tab-{{ insight.slug }}" *ngFor="let insight of insights;" [ngClass]="{'active': insight.active }" (click)="this.changeTab(insight.slug)">
      <span class="tab-icon" [ngStyle]="{'background-image': 'url(assets/img/icons/'+ insight.slug +'-'+ ((insight.active)?('white'):('green')) +'.svg)'}" [attr.data-cta]="insight.title">
        {{ insight.title }}
      </span>
    </div>
  </div>

  <div class="leadership-tab--container">
    <div class="f-row leadership-tab--description desc-{{ insight.slug }}" *ngFor="let insight of insights;" [ngClass]="{'active': insight.active }">
      <p *ngFor="let desc of insight.description">{{desc}}</p>
    </div>
    <div class="f-row leadership-tab--content owl-carousel owl-theme owl-{{ insight.slug }}" *ngFor="let insight of insights;" [ngClass]="{'active': insight.active }">
      <div class="f-col" *ngFor="let panel of insight.panels">

          <div class="top-box white hide-on-init" animateOnScroll animationName="animated fadeInUp">
              <div class="img-fill"><img src="{{ panel.img_src }}" alt="{{ panel.img_alt }}" /></div>
              <div class="article f-col">
                <h3>{{ panel.title }}</h3>
                {{ panel.description }}
                <div class="btn-group">
                  
                  <app-btn-download *ngIf="panel.download" 
                    href="{{ panel.download.href }}" 
                    title="{{ panel.title }}" 
                    titleatt="{{ panel.title }} (opens in new window)" 
                    download_title="{{ panel.title }}" 
                    color="btn__blue"></app-btn-download>
    
                  <app-btn-more *ngIf="panel.more" target="_blank" 
                    href="{{ panel.more.href }}" 
                    title="Read more" 
                    titleatt="{{ panel.titleatt }}" 
                    titlecta="{{ insight.title }}: {{ panel.titlecta }}" 
                    color="btn__blue"></app-btn-more>
                    
                  <app-btn-share
                    email_id="{{ panel.share.email_id }}"
                    url="{{ panel.share.url }}"
                    web_root="{{ panel.share.web_root }}"
                    social_title="{{ panel.share.social_title }}"
                    social_body="{{ panel.share.social_body }}"
                    email_subject="{{ panel.share.email_subject }}"
                    email_body="{{ panel.share.email_body }}"
                    color="btn__blue"></app-btn-share>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
</section>