<article class="page risk">
  <section class="parallax__background">
    <div class="hero" *ngIf="heroToggle">
      <div class="hero--panel sticky blue" *ngIf="heroToggle" [@flipInOut]>
        <div class="left-stripe">
          <h2>Risk Engineering</h2>
          <p>The reality is losses happen. They pose a threat to your business, your people, and your reputation.</p>
          <p>We are here to help you reduce the likelihood and severity of a loss by taking the time to understand your unique operation and providing customized recommendations to support your business.</p>
          <p class="learn-more"><a data-cta="Learn more: Risk Engineering" title="Learn more" (click)="app.navArrow.navigateTo('anchorNav')">Learn more&nbsp;&nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 7.5"><path d="M3185.921-7514.8l6.178,6.178,6.178-6.178" transform="translate(-3185.568 7515.154)" fill="none" stroke="#fff" stroke-width="1"/></svg></a></p>
        </div>
      </div>
    </div>
    <app-nav-arrow-bounce color="#150f96" destination="anchorNav"></app-nav-arrow-bounce>
  </section>

  <app-pagedown-bug title="Risk checklist" target="risk-checklist" backgroundImage="assets/img/bug-checklist@2x.png"></app-pagedown-bug>

  <app-nav-anchor-down [links]="links"></app-nav-anchor-down>

  <section  id="risk-more" class="quote f-row hide-on-init" animateOnScroll animationName="animated fadeInUp">
    <div class="f-col middle">
      <img src="assets/img/headshot_keithMarks@2x.jpg" width="428" alt="Photo of Keith Marks" />
    </div>
    <div class="f-col quote--text">
        <h3>Our biggest differentiator is our people. Our in-house network of risk engineers take the time to understand your business operation and align service strategies to your unique needs.</h3>
        <b>- Keith Marks</b>
        <p>EVP, Head of North America Risk Engineering</p>  
    </div>
  </section>

  <section class="page--background clearfix">
    <app-carousel-stats [carouselData]="carouselStats" class="hide-on-init" animateOnScroll animationName="animated fadeInUp" color="green"></app-carousel-stats>
  </section>
  
  <app-stagger-tiles [sectiondata]="section1"></app-stagger-tiles>

  <section class="page--background skinny f-row">
    <div class="f-row">
      <h2 class="header">Dive in to see our stories</h2>
    </div>
    <div class="f-col video-card hide-on-init" animateOnScroll animationName="animated fadeInUp">
      <div class="f-row video-card--image" style="background-image: url(assets/img/risk-section1-video1@2x.jpg);">
        <img src="assets/img/icons/video-play.png" width="72" height="70" class="icon-video-play" data-video-title="Katelyn’s Story: Winery Risk Engineer" (click)="app.playVideo('https://www.youtube.com/watch?v=pg63CzSkbx8', 'video/youtube', 'assets/img/risk-section1-video1@2x.jpg');" title="Play video" />
      </div>
      <div class="f-row video-card--info">
        <h2 class="box-header">Katelyn’s Story</h2>
        <h3>Winery Risk Engineer</h3>
        <div class="btn-group">
            <app-btn-watch color="btn--white" title="Katelyn’s Story: Winery Risk Engineer" (click)="app.playVideo('https://www.youtube.com/watch?v=pg63CzSkbx8', 'video/youtube', 'assets/img/risk-section1-video1@2x.jpg');"></app-btn-watch>
            <app-btn-share
              email_id="1_1"
              url="https://www.youtube.com/watch?v=pg63CzSkbx8"
              social_title="Chubb's Risk Engineering Expertise"
              social_body="Watch this short video about how Chubb's risk engineers are craftsman of insurance."
              email_subject="Chubb's Risk Engineering Expertise"
              email_body="Watch this short video about how Chubb's risk engineers are craftsman of insurance."
              color="btn--white"
            ></app-btn-share>
        </div>
      </div>
    </div>
    <div class="f-col video-card hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="f-row video-card--image" style="background-image: url(assets/img/risk-section1-video2c@2x.jpg);">
          <img src="assets/img/icons/video-play.png" width="72" height="70" class="icon-video-play" data-video-title="Mike’s Story: Property Casualty Risk Engineer" (click)="app.playVideo('https://www.youtube.com/watch?v=WIYr-soblDk', 'video/youtube', 'assets/img/risk-section1-video2c@2x.jpg');" title="Play video" />
        </div>
        <div class="f-row video-card--info">
          <h2 class="box-header">Mike’s Story</h2>
          <h3>Property Casualty Risk Engineer</h3>
          <div class="btn-group">
            <app-btn-watch color="btn--white" title="Mike’s Story: Property Casualty Risk Engineer" (click)="app.playVideo('https://www.youtube.com/watch?v=WIYr-soblDk', 'video/youtube', 'assets/img/risk-section1-video2c@2x.jpg');"></app-btn-watch>
            <app-btn-share
              email_id="1_2"
              url="https://youtu.be/WIYr-soblDk"
              social_title="Chubb's Risk Engineering Expertise"
              social_body="Watch this short video about how Chubb's risk engineers are craftsman of insurance."
              email_subject="Chubb's Risk Engineering Expertise"
              email_body="Watch this short video about how Chubb's risk engineers are craftsman of insurance."
              color="btn--white"
            ></app-btn-share>
            
          </div>
        </div>
      </div>
  </section>
  

  <app-risk-section2 [app]="app"></app-risk-section2>

  <app-risk-section3 [app]="app"></app-risk-section3>

  <app-risk-section4></app-risk-section4>

  <app-risk-explore-services></app-risk-explore-services>

  <app-footer-nav *ngIf="app.router.url !== '/intro'"></app-footer-nav>
  <app-footer></app-footer>

</article>