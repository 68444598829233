<span class="btn btn--share tooltip-wrap" [ngClass]="color">Share
    <svg class="share-icon" xmlns="http://www.w3.org/2000/svg" viewBox="-7201.1 1334.946 11 11.854">
        <g id="Group_7484" data-name="Group 7484" transform="translate(-7517 1054)">
            <path id="Path_2031" data-name="Path 2031" class="share-icon-1" d="M75.8,17.8l3.08,3.08L75.8,23.96"
                transform="translate(246.04 263.5)"></path>
            <line id="Line_1212" data-name="Line 1212" class="share-icon-2" x2="11" transform="translate(315.9 292.3)"></line>
            <path id="Path_2032" data-name="Path 2032" class="share-icon-1" d="M79.24,20.6H76.82a4.554,4.554,0,0,0-4.62,4.51"
                transform="translate(245.68 263.78)"></path>
        </g>
    </svg>
    <div class="share-tooltip Q2page">
        <a href="https://www.linkedin.com/shareArticle?mini=true&url={{ web_root }}{{ url }}&title={{ social_title }}&summary={{ social_body }}" target="_blank" title="LinkedIn" data-social-bookmark="LinkedIn"><em class="fa fa-linkedin"></em></a>
        <a href="https://www.facebook.com/sharer/sharer.php?u={{ web_root }}{{ url }}&title={{ social_title }}&description={{ social_body }}" target="_blank" title="Facebook" data-social-bookmark="Facebook"><em class="fa fa-facebook"></em></a>
        <a href="https://twitter.com/intent/tweet?text={{ social_body }}+{{ web_root }}{{ url }}" target="_blank" title="Twitter" data-social-bookmark="Twitter"><em class="fa fa-twitter"></em></a>
        <a href="#" title="Email" data-social-bookmark="Email" (click)="this.mailTo('mailto_'+ email_id); false"><em class="fa fa-envelope-o"></em></a>
    </div>
</span>
<div id="mailsubject_{{ email_id }}" style="display: none;">
    <ul>
        <li>{{ email_subject }}</li>
    </ul>
</div>
<div id="maillink_{{ email_id }}" style="display: none;">{{ web_root }}{{ url }}</div>
<div id="mailmessagelist_{{ email_id }}" style="display: none;">
    <ul>
        <li>{{ email_body }}</li>
    </ul>
</div>