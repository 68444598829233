import { Component, OnInit } from '@angular/core';
import { forEach } from '@angular/router/src/utils/collection';
import { HostListener } from "@angular/core";
declare const $: any;

@Component({
  selector: 'app-risk-section4',
  templateUrl: './risk-section4.component.html',
  styleUrls: ['./risk-section4.component.scss']
})
export class RiskSection4Component implements OnInit {

  webRoot = window.location.href.replace(window.location.hash, ``).replace(`index.aspx`, ``);
  insights = [
    {
      title: 'Water Damage',
      slug: 'water',
      active: true,
      description: [
        'An extensive analysis on 20 years of proprietary claims data for non-weather related water damage suggests there are nearly 60 independent factors associated with interior water damage, with the most common involving pipe bursts and water leaks. Rooftops, toilets, sprinkler systems, hot water heaters, and HVACs are some of the most common systems associated with interior water damage, with rooftops and elevators (shafts) associated with the most severe.',
        'Utilize the resources below to bolster your emergency response and preparedness for water-related incidents.'
      ],
      panels: [
        {
          img_src: 'assets/img/risk4/WaterDamageAssessment@2x.jpg',
          title: 'Water damage assessment and mitigation',
          titleatt: 'Water damage assessment and mitigation (opens in new window)',
          titlecta: 'Water damage assessment and mitigation',
          description: 'A thorough risk assessment includes water source identification, a site-specific feature evaluation, and an operational exposure assessment',
          share: {
            email_id: '98_1',
            web_root: this.webRoot,
            url: 'assets/pdf/TTP-Water_Damage_Assessment_and_Mitigation.pdf',
            social_title: 'Water damage assessment and mitigation',
            social_body: "A thorough risk assessment includes water source identification, a site-specific feature evaluation, and an operational exposure assessment. Learn more about preventing water-related incidents.",
            email_subject: "Water damage assessment and mitigation",
            email_body: "A thorough risk assessment includes water source identification, a site-specific feature evaluation, and an operational exposure assessment. Learn more about preventing water-related incidents.",
            color: 'btn--white'
          },
          download: {
            href: 'assets/pdf/TTP-Water_Damage_Assessment_and_Mitigation.pdf'
          }
        },
        {
          img_src: 'assets/img/risk4/WaterDamageEmergency@2x.jpg',
          title: 'Water damage emergency preparedness and response',
          titleatt: 'Water damage emergency preparedness and response (opens in new window)',
          titlecta: 'Water damage emergency preparedness and response',
          description: 'Water damage mitigation and planning checklist',
          share: {
            email_id: '98_2',
            web_root: this.webRoot,
            url: 'assets/pdf/TTP-Water_Damage_Emergency_Preparedness_and_Response.pdf',
            social_title: 'Water damage emergency preparedness and response ',
            social_body: "Bolster your emergency response and preparedness for water-related incidents with this checklist.",
            email_subject: "Water damage emergency preparedness and response",
            email_body: "This actionable checklist can help you improve your emergency preparedness and response to water-related incidents.",
            color: 'btn--white'
          },
          download: {
            href: 'assets/pdf/TTP-Water_Damage_Emergency_Preparedness_and_Response.pdf'
          }
        },
        {
          img_src: 'assets/img/risk4/RoofInspectionChecklist@2x.jpg',
          title: 'Roof inspection and maintenance',
          titleatt: 'Roof inspection and maintenance (opens in new window)',
          titlecta: 'Roof inspection and maintenance',
          description: 'Routine inspection and maintenance, program basics, and an inspection checklist',
          share: {
            email_id: '98_3',
            web_root: this.webRoot,
            url: 'assets/pdf/TTP-Roofs_and_Roof_Inspection_Checklist.pdf',
            social_title: "Roof inspection and maintenance",
            social_body: "Ensure you have proper roof inspection and maintenance practices in place to prevent water-damage with this actionable checklist.",
            email_subject: "Roof inspection and maintenance",
            email_body: "Ensure you have proper roof inspection and maintenance practices in place to prevent water-damage with this actionable checklist.",
            color: 'btn--white'
          },
          download: {
            href: 'assets/pdf/TTP-Roofs_and_Roof_Inspection_Checklist.pdf'
          }
        }
      ]
    },
    {
      title: 'Hurricane Preparedness',
      slug: 'hurricane',
      active: false,
      description: [
        'Typical insurance policies provide coverage for many of the perils associated with hurricanes. However, many insurance policies commonly exclude flood. And, 90% of natural disasters, especially hurricanes, include some form of flooding.',
        'Ask your agent how your current insurance program protects you from hurricanes and use the resources below to increase your hurricane preparedness.'
      ],
      panels: [
        {
          img_src: 'assets/img/risk4/HurricanePreparedness@2x.jpg',
          title: 'Hurricane Preparedness',
          titleatt: 'Hurricane Preparedness (opens in new window)',
          titlecta: 'Hurricane Preparedness',
          description: 'Actionable steps to take before, during, and after a hurricane',
          share: {
            email_id: '98_4',
            web_root: this.webRoot,
            url: 'assets/pdf/TTP-Hurricane_Preparedness.pdf',
            social_title: 'Hurricane preparedness',
            social_body: "Actionable steps to take before, during, and after a hurricane",
            email_subject: "Hurricane preparedness",
            email_body: "Actionable steps to take before, during, and after a hurricane",
            color: 'btn--white'
          },
          download: {
            href: 'assets/pdf/TTP-Hurricane_Preparedness.pdf'
          }
        },
        {
          img_src: 'assets/img/risk4/BusinessPreparedness@2x.jpg',
          title: 'Business preparedness and planning',
          titleatt: 'Business preparedness and planning (opens in new window)',
          titlecta: 'Business preparedness and planning',
          description: 'Preparation can help save lives and reduce damage to your business and reputation',
          share: {
            email_id: '98_5',
            web_root: '',
            url: 'https://www.chubb.com/us-en/claims/hurricane-windstorm-business-preparedness-planning.aspx',
            social_title: 'Business preparedness and planning for hurricanes',
            social_body: "When hurricanes threaten, preparation can help save lives and reduce damage to your business and reputation.  Learn more.",
            email_subject: "Business preparedness and planning for hurricanes",
            email_body: "Proper preparation for hurricanes can help save lives and reduce damage to your business and reputation.",
            color: 'btn--white'
          },
          more: {
            href: 'https://www.chubb.com/us-en/claims/hurricane-windstorm-business-preparedness-planning.aspx'
          }
        },
        {
          img_src: 'assets/img/risk4/WhenHurricanesThreaten@2x.jpg',
          title: 'When hurricanes threaten, planning makes the difference',
          titleatt: 'When hurricanes threaten, planning makes the difference (opens in new window)',
          titlecta: 'When hurricanes threaten, planning makes the difference',
          description: 'In depth look at hurricane preparedness',
          share: {
            email_id: '98_6',
            web_root: '',
            url: 'https://www.chubb.com/us-en/_assets/doc/chubb_hurricane.pdf',
            social_title: 'When hurricanes threaten, planning makes the difference',
            social_body: "When hurricanes threaten, planning makes the difference.  Take an indepth look at hurricane preparedness.",
            email_subject: "When hurricanes threaten, planning makes the difference",
            email_body: "An in depth look at hurricane preparedness.",
            color: 'btn--white'
          },
          download: {
            href: 'https://www.chubb.com/us-en/_assets/doc/chubb_hurricane.pdf'
          }
        }
      ]
    },
    {
      title: 'Flood',
      slug: 'flood',
      active: false,
      description: [
        'Floods are among the nation’s most common and costly natural disasters and cause billions of dollars in damage every year. Typical property insurance policies exclude the peril of flood. Further, some mistakenly assume that purchasing flood insurance is only necessary in high-risk flood zones. However, Chubb’s claim history indicates that over 50% of claim dollars paid for flood-related losses come from properties located outside of a high-risk flood zone.',
        'Utilize the resources below to better understand flood insurance and bolster your emergency response and preparedness for flood-related incidents.'
      ],
      panels: [
        {
          img_src: 'assets/img/risk4/PropertyFloodEndorsement@2x.jpg',
          title: 'Chubb’s property flood endorsement',
          titleatt: 'Chubb’s property flood endorsement (opens in new window)',
          titlecta: 'Chubb’s property flood endorsement',
          description: 'Our flood endorsement has specific flood limits of insurance and can be integrated with our property product to help protect against losses caused by flooding',
          share: {
            email_id: '98_7',
            web_root: '',
            url: 'https://appetiteapp.chubb.com/pdf/t3-flood-3-19.pdf',
            social_title: 'Insurance protection from flooding',
            social_body: "Typical property insurance policies exclude the peril of flood. Chubb's flood endorsement has specific flood limits of insurance and can be integrated with their property product to help protect against losses caused by flooding.",
            email_subject: "Insurance protection from flooding",
            email_body: "Typical property insurance policies exclude the peril of flood. Chubb's flood endorsement has specific flood limits of insurance and can be integrated with their property product to help protect against losses caused by flooding.",
            color: 'btn--white'
          },
          download: {
            href: 'https://appetiteapp.chubb.com/pdf/t3-flood-3-19.pdf'
          }
        },
        {
          img_src: 'assets/img/risk4/FloodEmergencyResponse@2x.jpg',
          title: 'Flood emergency response',
          titleatt: 'Flood emergency response (opens in new window)',
          titlecta: 'Flood emergency response',
          description: 'A flood emergency response plan is one component of an effective business continuity plan',
          share: {
            email_id: '98_8',
            web_root: this.webRoot,
            url: 'assets/pdf/TTP-Flood_Emergency_Response.pdf',
            social_title: 'Flood emergency response',
            social_body: "A flood emergency response plan is one component of an effective business continuity plan",
            email_subject: "Flood emergency response",
            email_body: "A flood emergency response plan is one component of an effective business continuity plan",
            color: 'btn--white'
          },
          download: {
            href: 'assets/pdf/TTP-Flood_Emergency_Response.pdf'
          }
        },
        {
          img_src: 'assets/img/risk4/FloodRecovery@2x.jpg',
          title: 'Flood recovery',
          titleatt: 'Flood recovery (opens in new window)',
          titlecta: 'Flood recovery',
          description: 'After a flood, promptly setting priorities for critical business functions and assessing property damage are the first steps in resuming operations',
          share: {
            email_id: '98_9',
            web_root: this.webRoot,
            url: 'assets/pdf/TTP-Flood_Recovery.pdf',
            social_title: 'Flood recovery',
            social_body: "After a flood, promptly setting priorities for critical business functions and assessing property damage are the first steps in resuming operations",
            email_subject: "Flood recovery",
            email_body: "After a flood, promptly setting priorities for critical business functions and assessing property damage are the first steps in resuming operations",
            color: 'btn--white'
          },
          download: {
            href: 'assets/pdf/TTP-Flood_Recovery.pdf'
          }
        }
      ]
    },
    {
      title: 'Wildfire',
      slug: 'wildfire',
      active: false,
      description: [
        'Over the last two decades, wildfires have become larger, longer-lasting, more frequent, and more destructive. The effects of climate change on increasing temperatures, decreased precipitation levels, and decreased soil moisture have increased the fire season and acres at risk. Depending on its cause and conditions, a wildfire can spread 15 miles or more in an hour. That’s why taking a little time to put a plan in place is a crucial step in protecting your business.',
        'Utilize the resources below to strengthen your emergency response and preparedness for wildfires.'
      ],
      panels: [
        {
          img_src: 'assets/img/risk4/WildlandFirePrevention@2x.jpg',
          title: 'Wildland fire prevention and commercial operations',
          titleatt: 'Wildland fire prevention and commercial operations (opens in new window)',
          titlecta: 'Wildland fire prevention and commercial operations',
          description: 'Best practice prevention tips to increase the chance of your facility surviving a wildfire',
          share: {
            email_id: '98_10',
            web_root: this.webRoot,
            url: 'assets/pdf/TTP-Wildland_Fire_Prevention.pdf',
            social_title: 'Wildland fire prevention and commercial operations',
            social_body: "Best practice prevention tips to increase the chance of your facility surviving a wildfire.",
            email_subject: "Wildland fire prevention and commercial operations",
            email_body: "Best practice prevention tips to increase the chance of your facility surviving a wildfire.",
            color: 'btn--white'
          },
          download: {
            href: 'assets/pdf/TTP-Wildland_Fire_Prevention.pdf'
          }
        },
        {
          img_src: 'assets/img/risk4/BusinessContinuity@2x.jpg',
          title: 'Business continuity planning',
          titleatt: 'Business continuity planning (opens in new window)',
          titlecta: 'Business continuity planning',
          description: 'How quickly you bounce back after a wildfire may depend on how well you plan before it',
          share: {
            email_id: '98_11',
            web_root: '',
            url: 'https://www.chubb.com/us-en/claims/wildfire-business-continuity-planning.aspx',
            social_title: 'Business continuity planning for wildfires',
            social_body: "How quickly you bounce back after a wildfire may depend on how well you plan before it.",
            email_subject: "Business continuity planning for wildfires",
            email_body: "How quickly you bounce back after a wildfire may depend on how well you plan before it.",
            color: 'btn--white'
          },
          more: {
            href: 'https://www.chubb.com/us-en/claims/wildfire-business-continuity-planning.aspx'
          }
        },
        {
          img_src: 'assets/img/risk4/WildfireRecovery@2x.jpg',
          title: 'Wildfire recovery',
          titleatt: 'Wildfire recovery (opens in new window)',
          titlecta: 'Wildfire recovery',
          description: 'Tips for resuming business operations after a wildfire',
          share: {
            email_id: '98_12',
            web_root: '',
            url: 'https://www.chubb.com/us-en/claims/wildfire-resuming-busines-opertions.aspx',
            social_title: 'Wildfire recovery',
            social_body: "Tips for resuming business operations after a wildfire",
            email_subject: "Wildfire recovery",
            email_body: "Tips for resuming business operations after a wildfire",
            color: 'btn--white'
          },
          more: {
            href: 'https://www.chubb.com/us-en/claims/wildfire-resuming-busines-opertions.aspx'
          }
        },
      ]
    }
  ];

  owl = null;

  @HostListener('window:hashchange', ['$event'])
  hashChangeHandler(e) {
    const hash = document.location.hash.split('#');
        if (hash[2] && hash[2] !== '/risk-engineering') {
          const tab = hash[2].split('-')[1];
          this.changeTab(tab);
        }
  }

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.insights.forEach(insight => {
    //   if (insight.active === true) {
        this.owl = $(`.leadership-tab--container`).find(`.owl-${insight.slug}`).owlCarousel({
          loop: false,
          margin: 0,
          // center: true,
          stagePadding: 0,
          nav: false,
          items: 3,
          dots: true,
          dotsEach: 1,
          responsive: {
            0: {
                items: 1
            },
            1024: {
                items: 2
            },
            1280: {
              items: 3
            }
        }
        });
        this.owl.on('changed.owl.carousel', function(event) {
          window.dataLayer.push({
            'event': 'carouselEvent',
            'eventCategory': 'Carousel Click',
            'eventAction': 'Clicked on carousel control',
            'eventLabel': 'Move to slide ' + (event.page.index + 1)
          });
        });

        const hash = document.location.hash.split('#');
        if (hash[2] && hash[2] !== '/risk-engineering') {
          const tab = hash[2].split('-')[1];
          this.changeTab(tab);
        }

    //   }
    });
  }

  changeTab(tab) {
    const currentTab = document.querySelector(`.tab-${tab}`) as HTMLElement;

    if (window.innerWidth < 1024) {//mobile
      if (currentTab.classList.contains('active')) {
        document.querySelectorAll(`.leadership-tab`).forEach(t => {
          t.classList.add('expand');
        });
      } else {
        document.querySelectorAll(`.leadership-tab`).forEach(t => {
          t.classList.remove('expand');
        });
        this.changeTabDesktop(tab);
      }
    } else {// desktop and tablet
      this.changeTabDesktop(tab);
    }
    //track tab change
    window.dataLayer.push({
      'event': 'tabEvent',
      'eventCategory': 'Tab Click',
      'eventAction': 'Clicked on tab navigation',
      'eventLabel': 'Open tab: ' + (tab)
    });
  }

  changeTabDesktop(tab) {
    const icon = document.querySelector(`.tab-${tab} .tab-icon`) as HTMLElement,
      currentTab = document.querySelector(`.tab-${tab}`) as HTMLElement;
    
    document.querySelectorAll(`.leadership-tab`).forEach(t => {
      t.classList.remove('active');
    });
    document.querySelectorAll('.leadership-tab--container .active').forEach(act => {
      act.classList.remove('active');
    });
    const icons = document.querySelectorAll(`.leadership-tabs .tab-icon`) as NodeListOf<HTMLElement>;
    icons.forEach(i => {
      i.style['background-image'] = i.style['background-image'].replace('-white', '-green');
    });

    currentTab.classList.add('active');
    icon.style['background-image'] = icon.style['background-image'].replace('-green', '-white');

    document.querySelectorAll(`.desc-${tab}`).forEach(t => {
      t.classList.add('active');
    });
    document.querySelectorAll(`.owl-${tab}`).forEach(t => {
      t.classList.add('active');
    });
  }

}
