<section id="explore" name="explore" class="page--background explore">
    <div class="explore--nav">
        <img src="assets/img/explore-meeting@2x.jpg" width="200" height="215" alt="Photo of meeting" class="header-explore" />
        <div class="f-col explore--header">
            <h2>Explore services</h2>
            <p>Dig in to learn more about what sets our claims services apart.</p>
            <img src="assets/img/icons/explore-open@2x.png" width="431" height="55" (click)="toggleDiv();" *ngIf="!toggleServices" class="explore--open" alt="Open Explore services" title="Open Explore services" />
            <img src="assets/img/icons/explore-close@2x.png" width="431" height="55" (click)="toggleDiv();" *ngIf="toggleServices" class="explore--close" alt="Close Explore services" title="Close Explore services" />
        </div>
        <img src="assets/img/explore-gears@2x.jpg" width="200" height="215" alt="Photo of gears" class="header-explore" />
    </div>
    
    <!-- <div class="explore--results" [@slideInOut]="this.animationState"> -->
    <div class="explore--results animated fadeIn" *ngIf="toggleServices">
        <div class="explore--details">
          <ul class="f-row">
              <li class="f-col" *ngFor="let service of services;">
                <h3>{{ service.header }}</h3>
                <p>{{ service.description }}</p>
              </li>
          </ul>
        </div>
    </div>
</section>