<section id="clients" name="clients" class="page--background skinny f-row">
  <div class="f-col">
      <div id="marker2" class="marker hide-on-init" animateOnScroll animationName="animated zoomIn"></div>
      <aside>
          <h1>Our clients</h1>
      </aside>
      <aside>
        <h3>Our risk mitigation techniques have a real impact for our clients. It’s easy to see why businesses like yours often consider our support an extension of their staff, and a key contributor to their success.</h3>
      </aside>
  </div>

  <div class="f-col tiles">
    <div class="tiles__stagger hide-on-init" animateOnScroll animationName="animated fadeInUp">
      <div class="tiles--image abs top left" style="background-image: url(assets/img/videos_trinchero@2x.jpg);" (click)="app.playVideo('https://www.youtube.com/watch?v=cNURg7WrwJQ', 'video/youtube', 'assets/img/videos_trinchero@2x.jpg');" data-video-title="Trinchero Family Estates">
        <img src="assets/img/icons/video-play.png" width="72" height="70" class="icon-video-play" title="Play video"  data-video-title="Trinchero Family Estates" />
      </div>
      <div class="tiles--info abs bottom right front blue">
        <h2>Trinchero Family Estates</h2>
        <p>Industry: <b>Wine</b></p>
        <div class="btn-group">
          <app-btn-watch color="btn--white" (click)="app.playVideo('https://www.youtube.com/watch?v=cNURg7WrwJQ', 'video/youtube', 'assets/img/videos_trinchero@2x.jpg');" title="Trinchero Family Estates"></app-btn-watch>
          <app-btn-share
            email_id="1_3"
            url="https://www.youtube.com/watch?v=cNURg7WrwJQ"
            social_title="Chubb's Risk Engineering Expertise"
            social_body="Hear about Chubb's risk engineering capabilities, directly from a Chubb client."
            email_subject="Chubb's Risk Engineering Expertise"
            email_body="Hear about Chubb's risk engineering capabilities, directly from a Chubb client."
            color="btn--white"
          ></app-btn-share>
        </div>


      </div>
    </div>

    <div class="tiles__stagger hide-on-init" animateOnScroll animationName="animated fadeInUp">
      <div class="tiles--image abs top left" style="background-image: url(assets/img/videos_dymax@2x.jpg);" (click)="app.playVideo('http://f1.media.brightcove.com/12/818971943001/818971943001_5783165344001_5783151197001.mp4?pubId=818971943001&videoId=5783151197001', 'video/mp4', 'assets/img/videos_dymax@2x.jpg');" title="Play video" data-video-title="Dymax on risk engineering">
        <img src="assets/img/icons/video-play.png" width="72" height="70" class="icon-video-play" data-video-title="Dymax on risk engineering" />
      </div>
      <div class="tiles--info abs bottom right front blue">
        <h2>Dymax on risk engineering</h2>
        <p>Industry: <b>Manufacturing</b></p>
        <div class="btn-group">
          <app-btn-watch color="btn--white" (click)="app.playVideo('http://f1.media.brightcove.com/12/818971943001/818971943001_5783165344001_5783151197001.mp4?pubId=818971943001&videoId=5783151197001', 'video/mp4', 'assets/img/videos_dymax@2x.jpg');" title="Dymax on risk engineering"></app-btn-watch>
          <app-btn-share
            email_id="1_4"
            url="http://players.brightcove.net/818971943001/default_default/index.html?videoId=5783151197001"
            social_title="Chubb's Risk Engineering Expertise"
            social_body="Hear about Chubb's risk engineering capabilities, directly from a Chubb client."
            email_subject="Chubb's Risk Engineering Expertise"
            email_body="Hear about Chubb's risk engineering capabilities, directly from a Chubb client."
            color="btn--white"
          ></app-btn-share>
        </div>
      </div>
      
    </div>
  </div>
</section>

<section class="page--background skinny f-row">
  <h2 class="header">Real Clients. Real Stories.</h2>
  <div class="carousel owl-stories owl-carousel owl-theme green">
    <div *ngFor="let cd of carouselTiles;">
      <div class="carousel--pad">
        <h3><img src="{{ cd.image }}" width="40" height="40" alt="{{ cd.alt }}" class="carousel--icon" /> {{ cd.title }}</h3>
        <p *ngFor="let c of cd.content">{{ c }}</p>
      </div>
      <div class="owl-dots carousel-point"></div>
    </div>
  </div>
</section>