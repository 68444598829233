<article class="page claims">

    <section class="parallax__background">
        <div class="hero" *ngIf="claimsHeroToggle">
            <div class="hero--panel sticky red" *ngIf="claimsHeroToggle" [@slideInOut]>
                <div class="left-stripe">
                    <h2>Claims</h2>
                    <p>We hope you never need to file a claim with us. But if you do, our priority is getting your operation back up and running as quickly as possible by settling your claim quickly and fairly.</p>
                    <p class="learn-more"><a data-cta="Learn more: Claims" title="Learn more" (click)="app.navArrow.navigateTo('anchorNav')">Learn more&nbsp;&nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 7.5"><path d="M3185.921-7514.8l6.178,6.178,6.178-6.178" transform="translate(-3185.568 7515.154)" fill="none" stroke="#fff" stroke-width="1"/></svg></a></p>
                </div>
            </div>
        </div>
        <app-nav-arrow-bounce color="#f1352b" destination="anchorNav"></app-nav-arrow-bounce>
    </section>
    <app-pagedown-bug title="Report a Claim" target="reporting" backgroundImage="assets/img/bug-report-claim@2x.png"></app-pagedown-bug>
    <!-- app-claims-bug></!-->

    <app-nav-anchor-down [links]="links"></app-nav-anchor-down>
    
    <section class="quote f-row hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="f-col middle">
            <img src="assets/img/claims-quote-headshot@2x.jpg" width="428" alt="Photo of Megan Watt" />
        </div>
        <div class="f-col quote--text">
            <h3>We are best known for our superior claims handling. Our mission is to make your life easier, and our reputation suggests we do just that.</h3>
            <b>- Megan Watt</b>
            <p>EVP, Head of North America Claims</p>
        </div>
    </section>

    <!-- STATS -->
    <section class="page--background clearfix">
        <app-carousel-stats [carouselData]="carouselStats" class="hide-on-init" animateOnScroll animationName="animated fadeInUp" color="robins"></app-carousel-stats>
    </section>
    
    <app-stagger-tiles [sectiondata]="section1"></app-stagger-tiles>

    <section class="consultants page--background">
        <div class="f-row hide-on-init" animateOnScroll animationName="animated fadeInUp">
            <div class="f-col consultants--image"></div>
            <div class="f-col consultants--desc-one">
                <h3>Claims business consultants</h3>
                <p>A claims business consultant may be assigned to serve as your dedicated point of contact.</p>
                <ul>
                    <li>Onboard new clients</li>
                    <li>Build custom service plans</li>
                    <li>Coordinate claims handling for complex accounts</li>
                    <li>Regular engagement throughout the policy period</li>
                    <li>Ensure prompt and fair claim resolution</li>
                </ul>
            </div>
        </div>
        <div class="f-row consultants--reverse hide-on-init" animateOnScroll animationName="animated fadeInUp">
            <div class="f-col consultants--image" style="background-image:url(assets/img/half_danaRose@2x.jpg);"></div>
            <div class="f-col consultants--desc-two">
                <h3>Our Claim Business Consultants work with our property, casualty, workers compensation, and auto experts to tailor a claim service plan that closely aligns with your definition of world-class service.</h3>
                <b>- Dana Rose</b>
                <p>VP, Claims Business Consultant Manager</p>
            </div>
        </div>
    </section>

    <!-- SECTION TWO -->
    <section id="reputation" name="reputation" class="page--background skinny f-row reputation clearfix">
        <div class="f-col hide-on-init" animateOnScroll animationName="animated fadeInUp">
            <div id="marker2" class="marker hide-on-init" animateOnScroll animationName="animated zoomIn"></div>
            <aside>
                <h1>Our reputation</h1>
            </aside>
            <aside>
                <h3>In 2018, we were the only carrier to be recognized by brokers as the number one carrier for claims handling in more than one category.<span class="robins">*</span></h3>
            </aside>
        </div>
        
        <div class="f-col tiles hide-on-init" animateOnScroll animationName="animated fadeInUp">
            <div class="tiles__stagger">
                <div class="tiles--image abs top left"><img src="assets/img/claims-section2-tile1@2x.png" alt="#1 Ranking by Brokers for Property" width="305" /></div>
            </div>
            <div class="tiles__stagger">
                <div class="tiles--image abs top right"><img src="assets/img/claims-section2-tile2@2x.png" alt="#1 Ranking by Brokers for Management/Professional Liability" width="305" /></div>
            </div>
            <div class="tiles__stagger">
                <div class="tiles--image abs top left"><img src="assets/img/claims-section2-tile3@2x.png" alt="#2 Ranking by Brokers for Primary Casualty" width="305" /></div>
            </div>
            <div class="tiles__stagger">
                <div class="tiles--image abs top right">
                    <img src="assets/img/claims-section2-tile4@2x.png" alt="#4 Ranking by Brokers for Cyber" width="305" />
                    <ul class="attribution">
                        <li>According to 2018 Advisen claims satisfaction survey</li>
                    </ul>
                </div>
            </div>
            
        </div>
    </section>

    <!--
    <section class="page--background skinny testimonial">
        <h2>Claims testimonial</h2>
        <div class="f-row video-row hide-on-init" animateOnScroll animationName="animated fadeInUp">
            <div class="f-col video-row--poster" style="background-image: url(assets/img/videos_corvetteMuseum@2x.jpg);" (click)="app.playVideo('https://www.youtube.com/watch?v=gpn81lrwdRo', 'video/youtube', 'assets/img/videos_corvetteMuseum@2x.jpg');" data-video-title="National Corvette Museum">
                <img src="assets/img/icons/video-play-robins@2x.png" width="72" height="70" class="icon-video-play" alt="Play video" title="Play video" data-video-title="National Corvette Museum" />
            </div>
            <div class="f-col video-row--info">
                <h3>National Corvette Museum</h3>
                <p>Industry: <b>Cultural Institutions</b></p>
                <div class="btn-group">
                    <app-btn-watch color="btn--white" (click)="app.playVideo('https://www.youtube.com/watch?v=gpn81lrwdRo', 'video/youtube', 'assets/img/videos_corvetteMuseum@2x.jpg');" title="National Corvette Museum"></app-btn-watch>
                    <app-btn-share
                        email_id="3_1"
                        color="btn--white"
                        url="https://www.youtube.com/watch?v=gpn81lrwdRo"
                        social_title="A client testimonial"
                        social_body="Check out a short video to see how Chubb responded when a sinkhole damaged a clients property. Take a look."
                        email_subject="A client testimonial"
                        email_body="Check out a short video to see how Chubb responded when a sinkhole damaged a clients property. Take a look."
                    ></app-btn-share>
                </div>
            </div>
        </div>
    </section>-->
    
    <app-claims-testimonials></app-claims-testimonials>
 
    <section class="page--background skinny f-row stories">
        <h2 class="header">Real Clients. Real Stories.</h2>
        <div class="carousel claims-stories owl-carousel owl-theme {{ carouselColor }}">
            <div *ngFor="let cd of claimsTiles;">
                <div class="carousel--pad">
                    <h3><img src="{{ cd.image }}" width="40" height="40" alt="{{ cd.alt }}" class="carousel--icon" /> {{ cd.title }}</h3>
                    <p *ngFor="let c of cd.content">{{ c }}</p>
                </div>
                <div class="owl-dots carousel-point"></div>
            </div>
        </div>
    </section>

    <!-- SECTION THREE -->
    <section id="resources" name="resources" class="page--background skinny f-row resources clearfix">
        <div class="f-col clearfix">
            <div id="marker3" class="marker hide-on-init" animateOnScroll animationName="animated zoomIn"></div>
            <aside>
                <h1>Resource center</h1>
            </aside>
            <aside>
                <h3>Dig deeper to learn more about our claims expertise.</h3>
            </aside>
        </div>
        
    </section>

    <section id="resources-flagship" name="resources-flagship" class="page--background skinny f-row clearfix" style="padding-top: 50px;">
        <div class="f-col flagship-container">
            <app-download-sidebyside [data]="flagship"></app-download-sidebyside>
        </div>
    </section>

    <app-claims-explore-services></app-claims-explore-services>

    <section id="reporting" class="reporting">
        <div class="f-row reporting--box">
            <div class="f-col reporting--description">
                <h2>Claim reporting and resolution</h2>
                <p>We provide 24/7/365 access to live representatives and instant connection to emergency mitigation and restoration services. With three convenient ways to report a claim, reporting a claim is easy.</p>
            </div>
            <div class="f-col reporting--icons">
                <div class="f-row hide-on-init" animateOnScroll animationName="animated fadeInUp">
                    <div class="f-col reporting--icon">
                        <svg version="1.1" id="icon-phone" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="72" height="72" viewBox="0 0 90 90" style="enable-background:new 0 0 90 90;" xml:space="preserve">
                            <style type="text/css">
                                .st0{fill:#01C1D6;}
                            </style>
                            <g>
                                <path class="st0" d="M67.6,26.2H22.5c-1.7,0-3.1,1.4-3.1,3v14.6h12.8v-6h25.7v6h12.8v-2.4H60.2v-6H29.8v6h-8V28.5h46.1v10.4h2.8v-9.8C70.7,27.6,69.2,26.2,67.6,26.2"/>
                                <polyline class="st0" points="55.4,40.2 34.5,40.2 34.5,46.3 19.4,57.2 19.4,58.7 21.9,58.7 36.9,47.8 36.9,42.7 53.1,42.7 53.1,47.8 68.2,58.7 68.2,61 19.4,61 19.4,63.8 70.7,63.8 70.7,57.2 55.4,46.3 "/>
                                <path class="st0" d="M51.8,52c0-3.8-3.1-6.8-6.8-6.8c-3.7,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8C48.8,58.8,51.8,55.8,51.8,52 M40.7,52c0-2.4,1.9-4.3,4.3-4.3s4.3,1.9,4.3,4.3c0,2.4-1.9,4.3-4.3,4.3C42.7,56.2,40.7,54.3,40.7,52"/>
                            </g>
                        </svg>
                    </div>
                    <div class="f-col reporting--content">1-800-CLAIMS-0</div>
                </div>

                <div class="f-row hide-on-init" animateOnScroll animationName="animated fadeInUp">
                    <div class="f-col reporting--icon">
                        <svg version="1.1" id="icon-handshake" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="72" height="72" viewBox="0 0 90 90" style="enable-background:new 0 0 90 90;" xml:space="preserve">
                            <style type="text/css">
                                .st0{fill:#01C1D6;}
                            </style>
                            <path class="st0" d="M65.6,45.9c-0.5,0.9-1.1,1.8-1.6,2.6c-0.6,0.9-1.5,1.6-2.2,2.4c-2,2-0.7,0.8-2.7,2.7
                                c-0.2,0.2-13.2-13.4-13.2-13.4c0.8-0.8,0.3-2.2-0.8-2.3c0,0-5.4-0.2-7-1c-0.5-0.2-1.6-1-1.6-1.5l21.3-3.8l2.3-2.2
                                c0,0,2.4,2.2,3.6,3.3c2,2,4,4,5.9,5.9c0.2,0.2,0.2,0.4,0.1,0.6c-0.7,1.2-1.4,2.4-2.1,3.6h3l1.4-2.2l0.6-1.1c0.3-0.6,0.2-1.2-0.3-1.7
                                l-11-11.1c-0.6-0.6-1.5-0.6-2,0c-1,1-2.4,2.4-2.5,2.4c-0.5,0.1-1,0.1-1.6,0.2c-2.6,0.3-12.3,2.4-13,2.3c-0.6-0.1-7.6-2.3-7.6-2.3
                                l-4.2-2.5c-0.5-0.3-1.2-0.2-1.6,0.2L17.6,38.3c-0.5,0.5-0.6,1.4-0.1,1.9c0,0,14.8,17.7,19.8,23.6c6.5,0,22.1-7.4,22.1-7.4l6.2-5.2
                                l3.3-5.4L65.6,45.9L65.6,45.9z M54.8,55.4L54.8,55.4c-0.5,0.2-1,0.1-1.4-0.2c-2.2-2.2-7.9-7.8-7.9-7.8l-1.9,1.9l6.2,6.3
                                c0.6,0.6,0.4,1.7-0.4,2l0,0c-0.5,0.2-1,0.1-1.3-0.3l-6.2-6.5L40,52.7l4.9,4.9c0.7,0.7,0.4,1.8-0.5,2.1c-0.5,0.2-1,0.1-1.4-0.3
                                l-5.1-5.3l-1.8,1.8l3.7,3.9c0.5,0.6,0.3,1.6-0.4,1.9c-0.6,0.2-1.3,0.1-1.7-0.4L19.5,39.1l9.7-9.8l3.8,2.3l3.5,1.2
                                c0,0-1.1,0.2-2.4,0.6c-1.1,0.3-1.4,1.6-0.6,2.4c1,1,2.2,2.2,3,2.9c0.5,0.5,1.1,0.8,1.8,0.9c1.8,0.3,4.7,0.6,4.7,0.6
                                c-0.2,0.2-0.2,0.6,0,0.8l12.2,12.4C55.8,54,55.6,55.1,54.8,55.4"/>
                        </svg>
                    </div>
                    <div class="f-col reporting--content">Contact your local Chubb agent or broker</div>
                </div>

                <div class="f-row reporting--row hide-on-init" animateOnScroll animationName="animated fadeInUp">
                    <div class="f-col reporting--icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="260 492 90 90" width="72" height="72">
                                <defs>
                                    <style>.a{clip-path:url(#b);}.b,.c,.d{fill:none;stroke:#01C1D6;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:3px;}.b{stroke-linecap:square;}.d{stroke-linecap:round;}.e{fill:#fff;}</style>
                                    <clipPath id="b"><rect x="260" y="492" width="90" height="90"/></clipPath>
                                </defs>
                                <g id="a" class="a"><rect class="e" x="260" y="492" width="90" height="90"/><g transform="translate(271.5 513.5)"><path class="b" d="M8.5,8.341V32.6H56.814V1.5H8.5" transform="translate(0.482)"/><path class="c" d="M64.778,44.5H1.5L8.982,30.6H57.3Z" transform="translate(0 2.005)"/><path class="d" d="M20.2,43.979,22.445,38.1H41.578l2.138,5.665" transform="translate(1.288 2.521)"/><rect class="d" width="34.525" height="17.743" transform="translate(15.93 8.341)"/></g></g>
                            </svg>
                    </div>
                    <div class="f-col reporting--content">
                        <a target="_blank" href="https://www.chubb.com/claims" title="chubb.com/claims (opens in new window)">www.chubb.com/claims</a>
                    </div>
                </div>
            </div>
        </div>

    </section>

    <section class="page--background disclaimer">
        <p>* Scores are for personal lines and commercial combined for client surveys conducted by Chubb.</p>
    </section>

    <app-footer-nav *ngIf="app.router.url !== '/intro'"></app-footer-nav>
    <app-footer></app-footer>

</article>