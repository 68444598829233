/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-arrow-bounce.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./nav-arrow-bounce.component";
var styles_NavArrowBounceComponent = [i0.styles];
var RenderType_NavArrowBounceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavArrowBounceComponent, data: {} });
export { RenderType_NavArrowBounceComponent as RenderType_NavArrowBounceComponent };
function View_NavArrowBounceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "full-next"], ["data-cta", "Next Arrow"], ["id", "navNext"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.navigateTo(_co.destination) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), i1.ɵdid(3, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [["aria-hidden", "true"], ["class", "svg-inline--fa fa-angle-down fa-w-10"], ["data-icon", "angle-down"], ["data-prefix", "fas"], ["focusable", "false"], ["height", "40"], ["role", "img"], ["viewBox", "0 0 320 512"], ["width", "40"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["d", "M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"], ["fill", "currentColor"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, _co.color); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_NavArrowBounceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavArrowBounceComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NavArrowBounceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav-arrow-bounce", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_NavArrowBounceComponent_0, RenderType_NavArrowBounceComponent)), i1.ɵdid(1, 114688, null, 0, i4.NavArrowBounceComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavArrowBounceComponentNgFactory = i1.ɵccf("app-nav-arrow-bounce", i4.NavArrowBounceComponent, View_NavArrowBounceComponent_Host_0, { color: "color", destination: "destination", offsetY: "offsetY" }, {}, []);
export { NavArrowBounceComponentNgFactory as NavArrowBounceComponentNgFactory };
