import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
    let url = this.router.url;
    url = url.replace(/%40/gi, '@')
              .replace(/%3A/gi, ':')
              .replace(/%24/gi, '$')
              .replace(/%23/gi, '#')
              .replace(/%2C/gi, ',')
              .replace(/%3B/gi, ';')
              .replace(/%2B/gi, '+')
              .replace(/%3D/gi, '=')
              .replace(/%3F/gi, '?')
              .replace(/%2F/gi, '/');
    if (url !== this.router.url) {
      window.location.assign('#' + url);
    }
  }
}
