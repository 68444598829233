import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-stagger-tiles',
  templateUrl: './stagger-tiles.component.html',
  styleUrls: ['./stagger-tiles.component.scss']
})
export class StaggerTilesComponent implements OnInit {

  @Input() sectiondata: any;
  
  constructor() { }

  ngOnInit() {
  }

}
