/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./btn-routerlink.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./btn-routerlink.component";
var styles_BtnRouterlinkComponent = [i0.styles];
var RenderType_BtnRouterlinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BtnRouterlinkComponent, data: {} });
export { RenderType_BtnRouterlinkComponent as RenderType_BtnRouterlinkComponent };
export function View_BtnRouterlinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [], [[1, "data-cta", 0], [8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(2, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["aria-hidden", "true"], ["class", "svg-inline--fa fa-angle-right fa-w-8"], ["data-icon", "angle-right"], ["data-prefix", "fas"], ["focusable", "false"], ["role", "img"], ["style", "transition: all 600ms ease-out 0s; opacity: 1; width:10px; height: 20px;"], ["viewBox", "0 0 256 512"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:path", [["d", "M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"], ["fill", "currentColor"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = i1.ɵinlineInterpolate(1, "btn ", _co.class, ""); var currVal_5 = _co.color; _ck(_v, 1, 0, currVal_4, currVal_5); var currVal_6 = _co.link; _ck(_v, 2, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titlecta; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.title, ""); var currVal_2 = i1.ɵnov(_v, 2).target; var currVal_3 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_7 = _co.title; _ck(_v, 3, 0, currVal_7); }); }
export function View_BtnRouterlinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-btn-routerlink", [], null, null, null, View_BtnRouterlinkComponent_0, RenderType_BtnRouterlinkComponent)), i1.ɵdid(1, 114688, null, 0, i4.BtnRouterlinkComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BtnRouterlinkComponentNgFactory = i1.ɵccf("app-btn-routerlink", i4.BtnRouterlinkComponent, View_BtnRouterlinkComponent_Host_0, { link: "link", title: "title", titlecta: "titlecta", color: "color", class: "class" }, {}, []);
export { BtnRouterlinkComponentNgFactory as BtnRouterlinkComponentNgFactory };
