import { Component, ElementRef, HostListener, OnInit, ViewEncapsulation, ViewChild, Host } from '@angular/core';
import { SlideInOutAnimation, FlipInOutAnimation } from 'src/app/app.animations';
import { AppComponent } from 'src/app/app.component';
import { DomSanitizer } from '@angular/platform-browser';
declare const $: any;

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./claims.component.scss'],
  animations: [ SlideInOutAnimation, FlipInOutAnimation ]
})
export class ClaimsComponent implements OnInit {
  webRoot = window.location.href.replace(window.location.hash, ``).replace(`index.aspx`, ``);
  links = [
    {
      title: 'Our professionals',
      mobTitle: 'Professionals',
      href: 'claims',
      fragment: 'professionals',
    },
    {
      title: 'Our reputation',
      mobTitle: 'Reputation',
      href: 'claims',
      fragment: 'reputation',
    },
    {
      title: 'Resource center',
      mobTitle: 'Resources',
      href: 'claims',
      fragment: 'resources',
    }
  ];

  section1 = {
    title: 'professionals',
    header: 'Our professionals',
    description: 'Meet a few of our claims specialists.',
    color: 'red',
    tiles: [
      {
        x: 'left',
        y: 'bottom',
        img: {
          src: 'assets/img/claims-section1-tile1@2x.jpg',
          x: 'right',
          y: 'top',
        },
        name: 'Tina L.',
        region: 'Northeast Region',
        jobtitle: 'Senior Claim Business Consultant',
        description: 'Advanced skills for workers comp'
      },
      {
        x: 'right',
        y: 'top',
        img: {
          src: 'assets/img/claims-section1-tile2@2x.jpg',
          x: 'left',
          y: 'bottom',
        },
        name: 'Michael D.',
        region: 'Southwest Region',
        jobtitle: 'Regional Claims Executive',
        description: 'Frequent speaker and panelist on the issues of corporate diversity'
      },
      {
        x: 'left',
        y: 'bottom',
        img: {
          src: 'assets/img/claims-section1-tile3@2x.jpg',
          x: 'right',
          y: 'top',
        },
        name: 'Karla H.',
        region: 'Pacific Region',
        jobtitle: 'Claim Business Consultant',
        description: '35 years of claim experience'
      }
    ]
  };

  points = [];

  carouselColor = 'robins';
  carouselStats = [
    {
      id: 'claims-stat1',
      img: 'assets/img/claims-stat-1@2x.png',
      svg: this.sanitizer.bypassSecurityTrustHtml('<svg xmlns="http://www.w3.org/2000/svg" width="328" height="180" viewBox="0 0 328 180"><g id="_740k" data-name="740k" transform="translate(-72 -1501)"><g id="Rectangle_3560" data-name="Rectangle 3560" transform="translate(72 1501)" fill="#fff" stroke="#22aac1" stroke-width="4" opacity="0.75"><rect width="328" height="180" stroke="none"/><rect x="2" y="2" width="324" height="176" fill="none"/></g><text id="claims-stat1" data-name="740k" transform="translate(102 1598)" fill="#22aac1" font-size="86" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="0">740</tspan><tspan x="140" y="0">K</tspan></text><text id="new_claims_annually" data-name="new claims annually" transform="translate(103 1622)" fill="#333" font-size="20" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="16">new claims annually</tspan></text></g></svg>'),
      alt: '750K new claims annually',
      animation: { c: 1, end: 750 }
    },
    {
      id: 'claims-stat2',
      img: 'assets/img/claims-stat-2@2x.png',
      svg: this.sanitizer.bypassSecurityTrustHtml('<svg xmlns="http://www.w3.org/2000/svg" width="328" height="180" viewBox="0 0 328 180"><g id="_2400pros" data-name="2400pros" transform="translate(-424 -1501)"><g id="Rectangle_3560" data-name="Rectangle 3560" transform="translate(424 1501)" fill="#fff" stroke="#22aac1" stroke-width="4" opacity="0.75"><rect width="328" height="180" stroke="none"/><rect x="2" y="2" width="324" height="176" fill="none"/></g><text id="claims-stat2" data-name="2400" transform="translate(454 1598)" fill="#22aac1" font-size="86" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="0">2400</tspan></text><text id="expert_claim_professionals_" data-name="expert claim professionals" transform="translate(455 1622)" fill="#333" font-size="20" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="16">claims professionals serving</tspan><tspan x="0" y="44">clients in North America</tspan></text></g></svg>'),
      alt: '2400 claims professionals serving clients in North America',
      animation: { c: 1000, end: 2400 }
    },
    {
      id: 'claims-stat3',
      img: 'assets/img/claims-stat-3@2x.png',
      svg: this.sanitizer.bypassSecurityTrustHtml('<svg xmlns="http://www.w3.org/2000/svg" width="328" height="180" viewBox="0 0 328 180"><g id="_96percent" data-name="96percent" transform="translate(-776 -1501)"><g id="Rectangle_3558" data-name="Rectangle 3558" transform="translate(776 1501)" fill="#fff" stroke="#22aac1" stroke-width="4" opacity="0.75"><rect width="328" height="180" stroke="none"/><rect x="2" y="2" width="324" height="176" fill="none"/></g><text id="claims-stat3" data-name="96%" transform="translate(809 1598)" fill="#22aac1" font-size="86" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="0">96</tspan><tspan x="100px" y="0">%</tspan></text><text id="of_clients_are_highly_satisfied_with_their_claims_experience" data-name="of clients are highly satisfied with their claims experience" transform="translate(810 1622)" fill="#333" font-size="20" font-family="Publico-Headline-Roman, Publico, georgia, serif"><tspan x="0" y="16">of clients are highly satisfied </tspan><tspan x="0" y="44">with their claims experience*</tspan></text></g></svg>'),
      alt: '96% of clients are highly satisfied with their claims experience*',
      animation: { c: 1, end: 96 }
    }
  ];
  carouselTileItems = [0, 1, 2, 3, 4, 5, 6];
  claimsTiles = [
    {
      title: 'A manufacturer’s employee suffers injury',
      image: 'assets/img/icons/workers-comp-robins.svg',
      alt: 'Workers Comp icon',
      content: [
        'An employee working in our manufacturing facility became entangled in a piece of equipment he was operating and suffered extensive injuries that required multiple surgeries. He brought suit against us and the distributor who had serviced the equipment and allegedly failed to warn about missing safety devices. Chubb responded and quickly paid our portion of the claim.'
      ]
    },
    {
      title: 'Counterfeit accessories lead to defective products',
      image: 'assets/img/icons/cellphone-accessories-robins.svg',
      alt: 'Cell Phone icon',
      content: [
        'We unknowingly distributed counterfeit cell phone accessories that were manufactured by a foreign vendor. After the products were distributed, several of the accessories overheated causing fires in our customers’ warehouses. Chubb’s general liability policy responded to the claims for bodily injury and property damage resulting from the defective products.'
      ]
    },
    {
      title: 'Electrical disturbance threatens research operation',
      image: 'assets/img/icons/electrical-robins.svg',
      alt: 'Electrical icon',
      content: [
        'Our large university’s key biomedical research building suffered an electrical disturbance to a main incoming electrical line causing property damage to building control systems and endangering our multimillion-dollar research operation. Chubb’s equipment breakdown claims team helped coordinate the rental of emergency generators and replacement of damaged electrical panels and controls.'
      ]
    },
    {
      title: 'Product failure leads to explosion',
      image: 'assets/img/icons/explosion-robins.svg',
      alt: 'Explosion icon',
      content: [
        'A vacuum vessel we manufactured exploded at a customer’s plant in Europe causing one fatality and injury to others. Our General Liability policy included Chubb’s Global Extension and responded to the bodily injury and property damage lawsuits filed in Europe. Chubb’s multinational claims unit understood the local litigation climate and was instrumental in settling the claim for less than the original demand.'
      ]
    },
    {
      title: 'Fire causes roof to collapse',
      image: 'assets/img/icons/fire-robins.svg',
      alt: 'Fire icon',
      content: [
        'When the first floor of our South Korea–based processing plant caught fire, the flames spread quickly, causing the roof to collapse. The building was a total loss. We exhausted our local  policy’s property damage limits and didn’t have business interruption on that policy. But thankfully, we had Chubb’s multinational program in place.  The multinational program allowed us to replace the damaged property, provided income replacement during the period required to do so, and allowed us to get our business back up to speed.'
      ]
    },
    {
      title: 'Threats on social media result in delay',
      image: 'assets/img/icons/socialmediathreat-robins.svg',
      alt: 'Social Threats icon',
      content: [
        'A young actress was scheduled to shoot as the main character for our production, but on the morning of the first day of principal photography, she received threats on social media from a stalker claiming he would come to the set. This frightened her, and production was delayed for one day. We hired extra security for the set and worked with the police to ensure safety for the whole cast and crew. Chubb paid for the delay in production and added expenses to secure the set.'
      ]
    },
    {
      title: 'Cyber-criminal demands ransom in Bitcoin',
      image: 'assets/img/icons/cyberattack-robins.svg',
      alt: 'Cyber icon',
      content: [
        'Our network was struck with a virulent strain of ransomware that encrypted data, crippled systems, and demanded ransom in Bitcoin. After consulting with Chubb’s cyber claims representative, an incident response coach, and forensic expert from Chubb’s cyber panel, we decided to pay the ransom. We received a decryption key that permitted access to the once-encrypted data. A forensic accountant from the Chubb panel assisted in the calculation of our business interruption loss. After comparing sales from the time of the attack to records of previous sales, Chubb reimbursed us for damages emanating from the attack.'
      ]
    }
  ];
  carouselTile = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  claimsHeroToggle = null;

  flagship = {
    title: "Flagship Claims brochure",
    description: "How is Chubb Claims different?",
    image: "assets/img/claims-flagship@2x.jpg",
    email_id: "2_1",
    download_href: "assets/pdf/T1-CIClaims-052919.pdf",
    download_title: "Flagship Claims brochure",
    titleatt: "Flagship Claims brochure (opens in new window)",
    url: "assets/pdf/T1-CIClaims-052919.pdf",
    social_title: "Learn how Chubb Claims is different",
    social_body: "You might be interested in this flagship claims brochure by Chubb. Take a look.",
    email_subject: "Learn how Chubb Claims is different",
    email_body: "You might be interested in this flagship claims brochure by Chubb. Take a look."
  };

  @HostListener('window:resize', ['$event']) checkResize() {
    this.slideChangeToggle();
  }

  @HostListener('window:scroll', ['$event']) checkScroll() {
    this.slideChangeToggle();
  }

  constructor(public el: ElementRef, @Host() public app: AppComponent, protected sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.slideChangeToggle();
  }

  ngAfterViewInit(): void {
    this.slideChangeToggle();
    $(document).ready(function(){
      const owl = $('.claims-stories').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText : ['<button class="leftRs"></button>',
                    '<button class="rightRs"></button>'],
        items: 1,
        dots: true,
        dotsContainer: ''
      });

      owl.on('changed.owl.carousel', function(event) {
        // console.log('e', event);
        window.dataLayer.push({
          'event': 'carouselEvent',
          'eventCategory': 'Carousel Click',
          'eventAction': 'Clicked on carousel control',
          'eventLabel': 'Move to slide ' + (event.page.index + 1)
        });
      });
    });
  }

  slideChangeToggle() {
    if (window.pageYOffset > window.innerHeight / 2 - 150) {
      this.claimsHeroToggle = false;
    } else {
      this.claimsHeroToggle = true;
    }
  }



  // onCarouselMove() {
  //   window.dataLayer.push({
  //     'event': 'carouselEvent',
  //     'eventCategory': 'Carousel Click',
  //     'eventAction': 'Clicked on carousel control',
  //     'eventLabel': 'Move to slide ' + (this.carousel.currentSlide + 1)
  //   });
  // }
}
