<section id="find-agent">
  <div class="f-col">
    <h1>Find an agent</h1>
    <h3>Connect with an agent for a custom quote</h3>
    <div>
      <app-btn-more title="Find an Agent in the U.S." titlecta="Find an Agent US" titleatt="Find an Agent in the U.S. (opens in new window)" target="_blank" color="btn__red" href="https://www.chubb.com/us-en/find-agent-page.aspx"></app-btn-more>

      <app-btn-more title="Find an Agent in Canada" titlecta="Find an Agent Canada" titleatt="Find an Agent in Canada (opens in new window)" target="_blank" color="btn__red" href="https://www.chubb.com/ca-en/find-a-broker.aspx"></app-btn-more>
    </div>
  </div>
</section>