/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer-nav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./footer-nav.component";
var styles_FooterNavComponent = [i0.styles];
var RenderType_FooterNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterNavComponent, data: {} });
export { RenderType_FooterNavComponent as RenderType_FooterNavComponent };
function View_FooterNavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "btn btn--white"], ["routerLinkActive", "active"]], [[1, "data-cta", 0], [1, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_4 = _v.context.$implicit.link; _ck(_v, 1, 0, currVal_4); var currVal_5 = "active"; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.titlecta; var currVal_1 = _v.context.$implicit.title; var currVal_2 = i1.ɵnov(_v, 1).target; var currVal_3 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_6); }); }
export function View_FooterNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "f-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Explore more"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "f-row btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterNavComponent_1)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.links; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_FooterNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer-nav", [], null, null, null, View_FooterNavComponent_0, RenderType_FooterNavComponent)), i1.ɵdid(1, 114688, null, 0, i4.FooterNavComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterNavComponentNgFactory = i1.ɵccf("app-footer-nav", i4.FooterNavComponent, View_FooterNavComponent_Host_0, {}, {}, []);
export { FooterNavComponentNgFactory as FooterNavComponentNgFactory };
