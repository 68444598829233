/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./btn-download.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./btn-download.component";
var styles_BtnDownloadComponent = [i0.styles];
var RenderType_BtnDownloadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BtnDownloadComponent, data: {} });
export { RenderType_BtnDownloadComponent as RenderType_BtnDownloadComponent };
export function View_BtnDownloadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["class", "btn btn--download"], ["data-btn-text", "Download"], ["target", "_blank"]], [[8, "href", 4], [1, "data-download-title", 0], [8, "title", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["Download "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, ":svg:svg", [["class", "download-icon"], ["viewBox", "0 0 11 11.3"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, ":svg:g", [["transform", "translate(-377.9 -2532.5)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["class", "download-icon-1"], ["d", "M412.5,194.5l5,5-5,5"], ["transform", "translate(583 2123) rotate(90)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:line", [["class", "download-icon-2"], ["transform", "translate(383.5 2532.5)"], ["y1", "8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:line", [["class", "download-icon-3"], ["transform", "translate(377.9 2543.3)"], ["x2", "11"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "btn btn--download"; var currVal_4 = _co.color; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.href, ""); var currVal_1 = _co.download_title; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.titleatt, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_BtnDownloadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-btn-download", [], null, null, null, View_BtnDownloadComponent_0, RenderType_BtnDownloadComponent)), i1.ɵdid(1, 114688, null, 0, i3.BtnDownloadComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BtnDownloadComponentNgFactory = i1.ɵccf("app-btn-download", i3.BtnDownloadComponent, View_BtnDownloadComponent_Host_0, { href: "href", title: "title", titleatt: "titleatt", download_title: "download_title", color: "color" }, {}, []);
export { BtnDownloadComponentNgFactory as BtnDownloadComponentNgFactory };
