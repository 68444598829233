<div id="flagship-claims" class="f-row flagship {{data.color}} hide-on-init" animateOnScroll animationName="animated fadeInUp">
    <div class="f-col">
        <img src="{{data.image}}" alt="{{data.title}}" class="flagship--image" />
    </div>
    <div class="f-col">
        <div class="flagship--info">
                <img src="assets/img/icons/new@2x.png" width="48" height="24" alt="NEW icon" />
                <h3>{{data.title}}</h3>
                <p>{{data.description}}</p>
                <div class="btn-group">
                    <app-btn-download 
                      href="{{data.download_href}}"
                      titleatt="{{data.titleatt}}"
                      download_title="{{data.download_title}}"></app-btn-download>
                    <app-btn-share
                        email_id="{{data.email_id}}"
                        web_root="{{webRoot}}"
                        url="{{data.url}}"
                        social_title="{{data.social_title}}"
                        social_body="{{data.social_body}}"
                        email_subject="{{data.email_subject}}"
                        email_body="{{data.email_body}}"></app-btn-share>
                </div>
        </div>
    </div>
</div>