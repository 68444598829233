import { Component, OnInit, Input, HostListener, ElementRef, asNativeElements } from '@angular/core';
import { $ } from 'protractor';

@Component({
  selector: 'app-nav-anchor-down',
  templateUrl: './nav-anchor-down.component.html',
  styleUrls: ['./nav-anchor-down.component.scss']
})
export class NavAnchorDownComponent implements OnInit {

  @Input() links: any;

  @HostListener('window:scroll', ['$event']) checkScroll() {
    this.stickyNav();
  }

  constructor(public el: ElementRef) { }

  ngOnInit() {
  }

  // Sticky Nav
  stickyNav() {
    const ne = this.el.nativeElement,
      anchorNav = document.querySelector('#anchorNav');
    if (window.pageYOffset > ne.offsetTop) {
      anchorNav.classList.add('sticky');
    } else {
      anchorNav.classList.remove('sticky');
    }

    // highlight based on page position
    this.links.forEach(l => {
      const frag = <HTMLElement> document.querySelector('#' + l.fragment),
        navfrag = document.querySelector('#nav' + l.fragment);
      if (window.pageYOffset > frag.offsetTop - 10) {
        document.querySelectorAll('.tab-nav__link').forEach(nl => {
          nl.classList.remove('active');
        });
        navfrag.classList.add('active');
      } else {
        navfrag.classList.remove('active');
      }
    });
  }

  scrollToElement($element, event): void {
    document.querySelector('#' + $element).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}
