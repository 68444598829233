import { Component, OnInit, HostListener, Input, Host, ElementRef } from '@angular/core';
import { IntroComponent } from 'src/app/pages/intro/intro.component';

@Component({
  selector: 'app-nav-arrow-bounce',
  templateUrl: './nav-arrow-bounce.component.html',
  styleUrls: ['./nav-arrow-bounce.component.scss']
})
export class NavArrowBounceComponent implements OnInit {
  @Input() color: any;
  @Input() destination: any;
  @Input() offsetY = 150;

  visible = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const whereto = document.getElementById(this.destination);
    if (window.pageYOffset < this.offsetY) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  constructor(public el: ElementRef) { }

  ngOnInit() {
  }

  navigateTo(destination) {
    if (destination === 'slide2') {// special case for intro page double hero slide
      window.scrollBy(0, 500);
      this.offsetY = 650;
      this.destination = 'intro-more';
    } else {
      document.querySelector('#' + destination).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
}
