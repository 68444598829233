<div class="relative">
    <a id="testimonials-1" class="offset"></a>
    <a id="testimonials-2" class="offset"></a>
    <a id="testimonials-3" class="offset"></a>
</div>
<section id="testimonials" class="page--background skinny">
  <h2 class="pad">Claims testimonials</h2>
  <br />
</section>
<section class="page--background no-image testimonials">
  <div class="f-row testimonials--container hide-on-init" animateOnScroll animationName="animated fadeInUp">
    <div class="f-col testimonials--col33">
      <h2>The Vermeer Story</h2>
      <p>A manufacturing company in the heartland, making a progressive impact around the world, overcomes the most severe tornado to hit Iowa in three years with Chubb’s help.</p>
      <p><a class="btn btn__red" title="Episode 1" (click)="slideChange(0)" [ngClass]="{'active': current === 0 }" data-cta="Episode 1">
          Episode 1
          <div class="icon">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" style="opacity: 1; width:10px; height: 20px;">
              <path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
            </svg>
          </div>
        </a></p>

      <p><a class="btn btn__red" title="Episode 2" (click)="slideChange(1)" [ngClass]="{'active': current === 1 }" data-cta="Episode 2">
          Episode 2
          <div class="icon">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" style="opacity: 1; width:10px; height: 20px;">
              <path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
            </svg>
          </div>
        </a></p>
      
        <p><a class="btn btn__red" title="Episode 3" (click)="slideChange(2)" [ngClass]="{'active': current === 2 }" data-cta="Episode 3">
          Episode 3
          <div class="icon">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" style="opacity: 1; width:10px; height: 20px;">
              <path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
            </svg>
          </div>
        </a></p>
    </div>

    <div class="f-col tiles testimonials--col66">
        <div class="carousel claims-testimonials owl-carousel owl-theme white">
            <div class="tiles__stagger" *ngFor="let cd of testimonials;">
              <div class="tiles--info abs right bottom front red">
                <h3>{{ cd.title }}</h3>
                <p *ngFor="let c of cd.content">{{ c }}</p>
                <app-btn-watch title="Watch episode {{ cd.episode_num }}" color="btn--white" (click)="this.app.playVideo(cd.video, 'video/youtube', cd.image);"></app-btn-watch>
                <app-btn-share
                  email_id="16_{{ cd.episode_num }}"
                  url="{{ cd.social.url }}"
                  social_title="{{ cd.social.title }}"
                  social_body="{{ cd.social.body }}"
                  email_subject="{{ cd.social.title }}"
                  email_body="{{ cd.social.email }}"
                  color="btn--white"
                ></app-btn-share>
              </div>
              <div class="tiles--image abs left top" [ngStyle]="{'background-image': 'url(' + cd.image + ')'}"></div>
            </div>
        </div>
    </div>
      
  </div>
</section>

