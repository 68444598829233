import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-share',
  templateUrl: './btn-share.component.html',
  styleUrls: ['./btn-share.component.scss']
})
export class BtnShareComponent implements OnInit {

  @Input() email_id: string;
  @Input() web_root: string;
  @Input() url: string;
  @Input() social_title: string;
  @Input() social_body: string;
  @Input() email_subject: string;
  @Input() email_body: string;
  @Input() color: string;

  constructor() { }

  ngOnInit() {
    this.url = encodeURIComponent(this.url);
  }

  mailTo(id) {
    // const id = $(this).attr('id');
    const mailsubject = id.replace('mailto', 'mailsubject');
    const sublist = document.querySelector('#' + mailsubject + ' ul li');
    const subject = sublist.innerHTML;
    const maillink = id.replace('mailto', 'maillink');
    const mailmessagelist = id.replace('mailto', 'mailmessagelist');
    const link = document.querySelector('#' + maillink).innerHTML.trim();
    const list = document.querySelector('#' + mailmessagelist + ' ul li');
    const body = list.innerHTML;

    let subjectstring = '';

    if (subject !== '') {
        subjectstring = '&subject=' + encodeURIComponent(subject);
    }

    let bodystring = '';
    if (body !== '') {
        bodystring = encodeURIComponent(body) + '%0D%0A%0D%0A';
    }

    const url =
        'mailto:?Content-type=text/html' +
        subjectstring +
        '&body=' +
        bodystring +
        link;
    window.location.href = url;
  }

}
