import { Component, OnInit, NgZone, HostListener } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
declare const $: any;

@Component({
  selector: 'app-claims-testimonials',
  templateUrl: './claims-testimonials.component.html',
  styleUrls: ['./claims-testimonials.component.scss']
})
export class ClaimsTestimonialsComponent implements OnInit {

  current = 0;
  testimonials = [
    {
      episode_num: 1,
      title: 'Episode 1: A Story of Resilience',
      image: 'assets/img/claims-testimonials-eplore-1@2x.jpg',
      video: 'https://www.youtube.com/watch?v=n9He9rNfXko',
      alt: 'Episode 1: A Story of Resilience',
      content: [
        'A 71 year-old company, with small-town loyalty, faces its toughest test yet – a devastating catastrophe'
      ],
      social: {
        url: 'https://youtu.be/n9He9rNfXko',
        title: `A Story of Resilience`,
        body: `See how a 71 year-old company, with small-town loyalty, faces its toughest test yet – a devastating catastrophe…`,
        email: `See how a 71 year-old company, with small-town loyalty, faces its toughest test yet – a devastating catastrophe…`
      }
    },
    {
      episode_num: 2,
      title: 'Episode 2: EF3 Tornado Strikes',
      image: 'assets/img/claims-testimonials-eplore-2@2x.jpg',
      video: 'https://www.youtube.com/watch?v=0YxHDOKOZx4',
      alt: 'Episode 2: EF3 Tornado Strikes',
      content: [
        'See how Chubb immediately responded to the devastation and helped Vermeer when it mattered most'
      ],
      social: {
        url: 'https://youtu.be/0YxHDOKOZx4',
        title: `EF3 Tornado Strikes`,
        body: `Learn how Chubb responded to a catastrophic event…`,
        email: `Learn how Chubb responded to a catastrophic event…`
      }
    },
    {
      episode_num: 3,
      title: 'Episode 3: Rising from the Rubble',
      image: 'assets/img/claims-testimonials-eplore-3@2x.jpg',
      video: 'https://www.youtube.com/watch?v=omV2YrhRzDk',
      alt: 'Episode 3: Rising from the Rubble',
      content: [
        'Chubb’s support and Vermeer’s pride and optimism proved stronger than the tornado'
      ],
      social: {
        url: 'https://youtu.be/omV2YrhRzDk',
        title: `Rising from the Rubble`,
        body: `See for yourself how Chubb works closely with clients to settle claims quickly and fairly…`,
        email: `See for yourself how Chubb works closely with clients to settle claims quickly and fairly…`
      }
    }
  ];

  owl = null;

  @HostListener('window:hashchange', ['$event'])
  hashChangeHandler(e) {
    this.hashChange();
  }

  constructor(public app: AppComponent, private zone:NgZone) { 
    window['angularComponentRef'] = {
      zone: this.zone,
      componentFn: (slide) => this.onChanged(slide),
      component: this
    };
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
      this.owl = $('.claims-testimonials').owlCarousel({
        loop: false,
        margin: 10,
        nav: true,
        navText : ['<button class="leftRs"></button>',
                    '<button class="rightRs"></button>'],
        items: 1,
        dots: false,
        dotsContainer: ''
      });

      this.owl.on('changed.owl.carousel', function(event) {
        window.dataLayer.push({
          'event': 'carouselEvent',
          'eventCategory': 'Carousel Click',
          'eventAction': 'Clicked on carousel control',
          'eventLabel': 'Move to slide ' + (event.page.index + 1)
        });
        window['angularComponentRef'].zone.run(() => {
          window['angularComponentRef'].component.onChanged(event.item.index);
        });
      });

      this.hashChange();
  }

  hashChange() {
    const hash = document.location.hash.split('#');
        if (hash[2] && hash[2] !== '/claims') {
          const testimonials = parseInt(hash[2].split('-')[1]);
          if (testimonials > 0) {
            this.slideChange(testimonials - 1);
          }
        }
  }

  onChanged(slide) {
    this.current = slide;
  }

  slideChange(slide) {
    this.owl.trigger('to.owl.carousel', [slide, 300]);
    this.onChanged(slide);
  }

}
