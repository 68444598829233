<article class="page intro">
  <section class="parallax__background skinny clearfix">

      <div *ngIf="heroToggle" id="hero" class="hero parallax__content parallax--center" [@slideInOut]>

        <!-- div *ngIf="slide1" class="hero--panel purple" [ngClass]="{sticky: slide1}">
          <h1 class="hide-on-init" animateOnScroll animationName="animated fadeIn">Chubb</h1>
          <h1 class="hide-on-init" animateOnScroll animationName="animated fadeIn">Services</h1>
        </!-->
        <div id="slide1" class="hero--panel purple">
          <div class="left-stripe">
            <h2>Chubb Services</h2>
            <p>We back our insurance products with industry-leading risk engineering and claims services so you can focus on running your business with the confidence and peace of mind of a Chubb Insured.<sup style="vertical-align: super;">SM</sup></p>
            <p class="learn-more"><a data-cta="Learn more: Services" (click)="app.navArrow.navigateTo('intro-more')">Learn more&nbsp;&nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 7.5"><path d="M3185.921-7514.8l6.178,6.178,6.178-6.178" transform="translate(-3185.568 7515.154)" fill="none" stroke="#fff" stroke-width="1"/></svg></a></p>
          </div>
        </div>
      </div>
      <app-nav-arrow-bounce color="#6e27c5" destination="intro-more"></app-nav-arrow-bounce>
  </section>

  <section id="intro-more" class="page--background skinny f-row middle">
    <div class="f-col">
        <aside>
            <h3>More than ever, frequency and severity of catastrophic events are up…</h3>
        </aside>
        <div id="marker1" class="marker hide-on-init" animateOnScroll animationName="animated zoomIn"></div>
        <aside>
            <h1>Disruptions are costly to your business.</h1>
        </aside>
    </div>

    <div class="f-col tiles">
      <div class="tiles__stagger hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--info abs top left front">
          <p>In the last five years, insurers have paid nearly</p>
          <h2>$200 billion</h2>
          <p>due to catastrophes following a spate of hurricanes, earthquakes, and wildfires.<sup>1</sup></p>
        </div>
        <div class="tiles--image abs bottom right" style="background-image: url(assets/img/intro-section1-tile1@2x.jpg);"></div>
      </div>

      <div class="tiles__stagger hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--info abs top right front">
          <p>In the last three years, the annual average number of billion-dollar disasters is</p>
          <h2>more than double</h2>
          <p>the long-term average.<sup>2</sup></p>
        </div>
        <div class="tiles--image abs bottom left" style="background-image: url(assets/img/intro-section1-tile2@2x.jpg);"></div>
      </div>

      <div class="tiles__stagger hide-on-init" style="height: 295px;" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--info abs bottom left front">
            <p>In 2018, there were</p>
            <h2>14 separate</h2>
            <p>billion-dollar disaster events, including two tropical cyclones, eight severe storms, two winter storms, a drought, and a wildfire.<sup>3</sup></p>
            <!--
              <p>In 2018, there were</p>
              <h2>14</h2>
              <p>separate billion-dollar disaster events, including two tropical cyclones, eight severe storms, two winter storms, a drought, and a wildfire.<sup>3</sup></p>
            -->
        </div>
        <div class="tiles--image abs top right" style="background-image: url(assets/img/intro-section1-tile3@2x.jpg);"></div>
      </div>
    </div>
  </section>

  <section class="page--background skinny f-row middle">
    <div class="f-col">
        <aside>
            <h3>Simultaneously, rapid transformation and continual advancement across key industries driving the economy are leading to new and evolving risks…</h3>
        </aside>
        <div id="marker2" class="marker hide-on-init" animateOnScroll animationName="animated zoomIn"></div>
        <aside>
            <h1>We understand the risks in your industry.</h1>
        </aside>
        <aside>
          <h3>
            These changes underscore the need for an insurer that is responsive to an evolving risk landscape and can provide integrated account solutions and services.
          </h3>
        </aside>
    </div>

    <div class="f-col tiles" style="padding-bottom: 130px;">
      <div class="tiles__middle hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--info left front text-center">
          <h3>Globalization</h3>
        </div>
        <div class="tiles--image right" style="background-image: url(assets/img/intro-section2-tile1@2x.jpg);"></div>
      </div>

      <div class="tiles__middle hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--image left" style="background-image: url(assets/img/intro-section2-tile2@2x.jpg);"></div>
        <div class="tiles--info right front text-center">
          <h3>Digitization / Internet of Things</h3>
        </div>
      </div>

      <div class="tiles__middle hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--info left front text-center">
          <h3>Supply chain dependencies</h3>
        </div>
        <div class="tiles--image right" style="background-image: url(assets/img/intro-section2-supplychain.jpg);"></div>
      </div>

      <div class="tiles__middle hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--image left" style="background-image: url(assets/img/intro-section2-cyber.jpg);"></div>
        <div class="tiles--info right front text-center">
          <h3>Cyber risk</h3>
        </div>
      </div>

    </div>
  </section>

  <section class="page--purple f-row">
    <div class="f-col">
        <div id="marker3" class="marker hide-on-init" animateOnScroll animationName="animated zoomIn"></div>
      <h1>We are anticipating these changes and continually evolving how we deliver value to you and your business.</h1>
      <br /><br />
      <h1>As the insurance industry shifts from a more reactive repair-and-replace approach to a proactive predict-and-prevent strategy, we lead the charge by providing cutting-edge risk engineering services that reduce the likelihood and severity of a loss, before it happens.</h1>
    </div>
  </section>

  <section class="page--background skinny f-row middle">
    <div class="f-col">
        <aside>
            <h3>We’ve invested in a partnership with The Ohio State University’s National Center for the Middle Market and a variety of other risk mitigation tools…</h3>
        </aside>
        <div id="marker4" class="marker hide-on-init" animateOnScroll animationName="animated zoomIn"></div>
        <aside>
            <h1>We help you stay one step ahead</h1>
        </aside>
        <aside>
          <h3>…to help you avoid costly business disruption.</h3>
        </aside>
    </div>

    <div class="f-col tiles">
      <div class="tiles__stagger hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--info abs top left front text-center">
          <h3>Industry-focused risk engineering services</h3>
        </div>
        <div class="tiles--image abs bottom right" style="background-image: url(assets/img/intro-section4-engineering.jpg);"></div>
      </div>

      <div class="tiles__stagger hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--info abs bottom right front text-center">
          <h3>State-of-the-art loss prevention lab to test innovations</h3>
        </div>
        <div class="tiles--image abs top left" style="background-image: url(assets/img/intro-section4-tile2@2x.jpg);"></div>
      </div>

      <div class="tiles__stagger hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--info abs bottom left front text-center">
          <h3>Outside perspectives and thought leadership</h3>
        </div>
        <div class="tiles--image abs top right" style="background-image: url(assets/img/intro-section4-tile3@2x.jpg);"></div>
      </div>
    </div>
  </section>

  <section class="page--background skinny f-row middle">
    <div class="f-col">
        <aside>
            <h3>While prevention is key, in the unfortunate event you need to file a claim with us, that’s our opportunity to show you why we’ve earned our claims reputation.</h3>
        </aside>
        <div id="marker5" class="marker hide-on-init" animateOnScroll animationName="animated zoomIn"></div>
        <aside>
            <h1>An iron-clad claims reputation</h1>
        </aside>
        <aside>
          <h3>We’re here to help.</h3>
        </aside>
    </div>

    <div class="f-col tiles">
      <div class="tiles__stagger hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--info abs bottom right front text-center">
          <h3>Prompt and fair payment</h3>
        </div>
        <div class="tiles--image abs top left" style="background-image: url(assets/img/intro-section5-tile1@2x.jpg);"></div>
      </div>

      <div class="tiles__stagger hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--info abs top left front text-center">
          <h3>Easy claims process with transparency each step of the way</h3>
        </div>
        <div class="tiles--image abs bottom right" style="background-image: url(assets/img/intro-section5-tile2@2x.jpg);"></div>
      </div>

      <div class="tiles__stagger hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <div class="tiles--info abs bottom right front text-center">
          <h3>Claims experts in our industry, and yours</h3>
        </div>
        <div class="tiles--image abs top left" style="background-image: url(assets/img/intro-section5-tile3@2x.jpg);"></div>
      </div>
    </div>
  </section>

  <section class="page--background wide">
    <div class="f-row" style="margin-left: 60px;">
        <h2>Dig in to learn more</h2>
    </div>
    <div class="f-row">
      <div class="f-col top-box hide-on-init blue" animateOnScroll animationName="animated fadeInUp">
        <img src="assets/img/subPage_riskEngineering@2x.jpg" alt="Risk Engineering photo" />
          
        <div class="article fill-white">
          <h3>Risk Engineering</h3>
          Our risk mitigation services help protect businesses against risk, rather than simply insuring against it.
          <div class="buttons">
            <app-btn-routerlink title="See Risk Engineering" titlecta="Risk Engineering" link="/risk-engineering" color="btn__blue" class="btn--tall"></app-btn-routerlink>
          </div>
        </div>
      </div>
      <div class="f-col top-box hide-on-init" animateOnScroll animationName="animated fadeInUp">
        <img src="assets/img/subPage_claims@2x.jpg" alt="Claims photo" />
            
        <div class="article fill-white">
          <h3>Claims</h3>
          By partnering with us, you gain access to our extensive claims expertise, experience, and service.
          <div class="buttons">
            <app-btn-routerlink title="See Claims" titlecta="See Claims" link="/claims" color="btn__red" class="btn--tall"></app-btn-routerlink>
          </div>
        </div>    
      </div>
    </div>
  </section>

  <section class="page--background skinny">
    <div class="disclaimer">
      <div class="item">1 Property Claims Service/ISO;  Insurance Information Institute as of Dec. 31, 2018.  Stated in 2018 dollars. Excludes NFIP losses.</div>
      <div class="item">2, 3 <a href="https://climate.gov" target="_blank">Climate.gov</a>; U.S. 2016-2018 results</div>
      <!--
      <div class="item">2 <a href="https://climate.gov" target="_blank">Climate.gov</a></div>
      <div class="item">3 <a href="https://climate.gov" target="_blank">Climate.gov</a></div>
      -->
    </div>
  </section>

  <app-footer-nav *ngIf="app.router.url !== '/intro'"></app-footer-nav>
  <app-footer></app-footer>
</article>
