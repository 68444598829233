<section id="resources" name="resources" class="page--background skinny">
    <div class="f-row">
      <div class="f-col alt">
        <div id="marker3" class="marker hide-on-init" animateOnScroll animationName="animated zoomIn"></div>
        <aside>
            <h1>Resource center</h1>
        </aside>
        <aside>
          <h3>We are continually investing in resources and tools to help you protect your business.</h3>
        </aside>
      </div>
    </div>
    

    <div class="f-row tiles--row hide-on-init" animateOnScroll animationName="animated fadeInUp">
      <div class="tiles" *ngFor="let resource of resources">
        <div class="tiles--image" [ngStyle]="{'background-image': 'url(' + resource.img_src + ')'}" (click)="app.playVideo(resource.watch, 'video/mp4', resource.image_src);" [attr.data-video-title]="resource.title">
          <img src="assets/img/icons/video-play.png" width="72" height="70" class="icon-video-play" title="Play video"  [attr.data-video-title]="resource.title" />
        </div>

        <div class="tiles--info">
          <h2>{{ resource.title }}</h2>
          <p>{{ resource.description }}</p>
          <ul>
            <li *ngFor="let item of resource.checklist">{{ item }}</li>
          </ul>
        </div>
        <div class="btn-group f-row">
          <app-btn-watch color="btn__blue" (click)="app.playVideo(resource.watch, 'video/mp4', resource.image_src);" title="{{resource.title}}"></app-btn-watch>
          <app-btn-more color="btn__blue" target="_blank" href="{{resource.explore}}" title="Explore" titlecta="{{ resource.titlecta }}" titleatt="{{ resource.titleatt }}"></app-btn-more>
          <p class="small gray spacer">(Requires login)</p>
        </div>
      </div>
  
    </div>
  </section>
  
  <section class="global-learning f-row">
    <div class="global-learning--content hide-on-init" animateOnScroll animationName="animated fadeInUp">
      <h2>Global Learning Centre</h2>
      <p>The new Chubb Global Learning Centre can bring exposures, and how to manage them, to life with hands-on training in the most innovative way possible.</p>
      <p>Located in Branchburg, NJ, this 14,000-square-foot, state-of-the-art, green facility is dedicated to expert training—fully equipped and staffed to deliver loss mitigation training in a safe and controlled environment.</p>
      <p>Our mission is to help companies, employees, municipalities, and business partners reduce the risk of loss and strengthen strategies to keep operations safer and profitable.</p>
      <app-btn-more title="Learn more" titleatt="Learn more Global Learning Centre (opens in new window)" color="btn__blue" href="assets/pdf/Chubb_Global_Learning_Centre.pdf" titlecta="Learn more Global Learning Centre" target="_blank"></app-btn-more>
    </div>
    <div class="f-col">&nbsp;</div>
    
  </section>

  <!-- <section id="risk-checklist" class="page--background skinny f-row resources clearfix">
    <app-download-sidebyside [data]="checklist"></app-download-sidebyside>
  </section> -->